import React, { useContext, useEffect, useRef, useState } from 'react';
import { ArrowDropDown, ArrowDropUp, Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, CircularProgress, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogTitle, Grow, IconButton, InputAdornment, MenuItem, MenuList, Paper, TextField, Typography } from '@mui/material';
import ClientSearchComponent from 'src/components/accountSearch/client-search';
import { StyledPopper } from '../rpm/rpm-validation'
import { CronSchedulerComponent } from 'src/components/scheduler/cronScheduler';
import { API_NS } from 'src/lib/api/endpoints/endpoint.types';
import { AsyncProcessState } from 'src/store/types/shared-state-types';
import { ArrowRightIcon } from 'src/components/icons/arrow-right';
import { useTypedAppSelector } from 'src/store';
import { intouchAction } from 'src/store/actions/intouch.actions';
import { useDispatch } from 'react-redux';
import { SnackBarContext } from 'src/contexts/snack-bar.context';
import { intouchSelectors } from 'src/store/selectors/intouch.selector';
import { userAction } from 'src/store/actions/user.actions';

export const deleteClientDialog = (openDeleteClient, setOpenDeleteClient, deleteClient) =>
  <Dialog fullWidth open={openDeleteClient} onClose={() => setOpenDeleteClient(false)}>
    <DialogContent style={{ fontSize: 15 }}>Are you sure you want to delete this client?</DialogContent>
    <DialogActions>
      <Button size='small' onClick={() => { setOpenDeleteClient(false); deleteClient() }}>Confirm</Button>
      <Button size='small' onClick={() => setOpenDeleteClient(false)}>Cancel</Button>
    </DialogActions>
  </Dialog>

export const addNewClientDialog = (openNewClient, setOpenNewClient, account, selectAccountHandler, clear, setClear, resetClear, addNewClient, hasAccess) =>
  <Dialog fullWidth open={openNewClient} onClose={() => setOpenNewClient(false)}>
    <DialogTitle>Add New Client</DialogTitle>
    <DialogContent>
      <ClientSearchComponent selectedAccount={account} selectAccountHandler={selectAccountHandler} clear={clear} resetClear={resetClear} disabled={hasAccess} />
    </DialogContent>
    <DialogActions>
      <Button size='small' disabled={!account} onClick={() => { setOpenNewClient(false); addNewClient() }}>Add</Button>
      <Button size='small' onClick={() => { setOpenNewClient(false); setClear(true);  }}>Cancel</Button>
    </DialogActions>
  </Dialog>

type newProptypes = {
  open: boolean;
  setOpen: (b: boolean) => void;
  customer: {LoginId: number, Login: string, Password: string, IsEnabled: boolean} | undefined;
  setCustomer: (c: {LoginId: number, Login: string, Password: string, IsEnabled: boolean} | undefined) => void;
  addCustomer: (c: any) => void;
}

export const AddNewCustomerDialog: React.FC<newProptypes & { className?: string }> = (props) => {
  const { className, open, setOpen, customer, setCustomer, addCustomer } = props
  const [details, setDetails] = useState<{LoginId?: number , Login?: string , Password?: string , IsEnabled?: boolean } | null | undefined>(customer)
  const [visible, setVisible] = useState<boolean>(false)

  return <Dialog className={className} fullWidth open={open} onClose={() => { setOpen(false); setCustomer(undefined) }}>
    <DialogTitle>Add New Customer</DialogTitle>
    <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
      <TextField
        fullWidth
        variant='outlined'
        size='small'
        placeholder='Name'
        inputProps={{ style: { padding: 10, fontSize: 15 } }}
        value={details?.Login || ''}
        onChange={e => setDetails({ ...details, Login: e.target.value })}
      />
      <TextField
        className='password-field'
        fullWidth
        required
        variant='outlined'
        size='small'
        type={visible ? 'text' : 'password'}
        placeholder='Password'
        inputProps={{ style: { padding: 10, fontSize: 15 }, autoComplete: 'new-password' }}
        value={details?.Password || ''}
        onChange={e => setDetails({ ...details, Password: e.target.value })}
        InputProps={{ endAdornment: <InputAdornment position='end'>
          <IconButton size='small' onClick={() => setVisible(!visible)}>
            {visible ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>}}
      />
    </DialogContent>
    <DialogActions>
      <Button size='small' disabled={(details?.Login || '').length === 0 || (details?.Password || '').length === 0} onClick={() => { setOpen(false); addCustomer(details) }}>
        Add
      </Button>
      <Button size='small' onClick={() => setOpen(false)}>Cancel</Button>
    </DialogActions>
  </Dialog>
}

type changeProptypes = {
  open: boolean;
  setOpen: (b: boolean) => void;
  customer: {LoginId: number, Login: string, Password: string, IsEnabled: boolean} | undefined;
  setCustomer: (c: {LoginId: number, Login: string, Password: string, IsEnabled: boolean} | undefined) => void;
  updatePassword: (p: any) => void;
  setClear: (c: boolean) => void;
}

export const ChangePasswordDialog: React.FC<changeProptypes & { className?: string }> = (props) => {
  const { open, setOpen, customer, setCustomer, updatePassword, setClear } =props
  const [details, setDetails] = useState<{LoginId?: number , Login?: string , Password?: string , IsEnabled?: boolean } | null | undefined>(customer)
  const [visible, setVisible] = useState<boolean>(false)

  return <Dialog fullWidth open={open} onClose={() => { setOpen(false); setCustomer(undefined) }}>
    <DialogTitle>Change Password</DialogTitle>
    <DialogContent>
      <TextField
        fullWidth
        required
        style={{ marginTop: 12 }}
        variant='outlined'
        size='small'
        type={visible ? 'text' : 'password'}
        placeholder='Password'
        inputProps={{ style: { padding: 10, fontSize: 15 }, autoComplete: 'new-password' }}
        value={details?.Password || ''}
        onChange={e => setDetails({ ...details, Password: e.target.value })}
        InputProps={{ endAdornment: <InputAdornment position='end'>
          <IconButton size='small' onClick={() => setVisible(!visible)}> {visible ? <VisibilityOff /> : <Visibility />}</IconButton>
        </InputAdornment>}}
      />
    </DialogContent>
    <DialogActions>
      <Button size='small' disabled={(details?.Password || '').length === 0} onClick={() => {setOpen(false); updatePassword(details) }}>Save</Button>
      <Button size='small' onClick={() => {setOpen(false); setClear(true) }}>Cancel</Button>
    </DialogActions>
  </Dialog>
}

export const EditIntouchClientDialog = React.memo((props: { mapping, open, setOpen, setMessage, loading, hasAccess }) => {
  const { mapping, open, setOpen, setMessage, loading, hasAccess } = props
  const [account, setAccount] = useState<{acc: API_NS.Accounts, type: 'customer'} | undefined>(undefined)
  const [clear, setClear] = useState<boolean>(false)
  const intouchClients = useTypedAppSelector(intouchSelectors.intouchClients)
  const dispatch = useDispatch()

  const resetClear = () => setClear(false)

  const selectAccount = (acc, type) => setAccount({ acc, type })

  const updateIntouchClient = () => {
    if (!account || !mapping) return

    const duplicate = intouchClients.filter(e => e === account.acc.ClientCode);
    if (duplicate.length > 0) {
      setMessage({ message: 'This Intouch client mapping already exists', severity: 'warning', clear: null })
      return
    }
    else
      dispatch(intouchAction.updateProviderMapping({
        Provider: mapping.Provider,
        ProviderClientMappingId: mapping.ProviderClientMappingId,
        HasMapping: mapping.HasMapping,
        ExternalClientId: mapping.ExternalClientId,
        Username: account.acc.ClientCode
      }))
  }

  useEffect(() => {
    dispatch(userAction.clearAccounts())
  }, [])

  return <Dialog maxWidth={false} style={{ width: '100%' }} PaperProps={{ style: { width: 420 } }} open={open} onClose={() => setOpen(false)}>
    <DialogTitle>InTouch Client</DialogTitle>
    <DialogContent>
      <ClientSearchComponent selectedAccount={account} selectAccountHandler={selectAccount} clear={clear} resetClear={resetClear} disabled={hasAccess} />
    </DialogContent>
    <DialogActions>
      <Button size='small' disabled={!account || loading} onClick={() => updateIntouchClient()}>Save</Button>
      <Button size='small' onClick={() => { setAccount(undefined); setOpen(false) }}>Cancel</Button>
    </DialogActions>
  </Dialog>
})

export const EditExternalClientDialog = React.memo((props: { mapping, open, setOpen, setMessage, loading }) => {
  const { mapping, open, setOpen, setMessage, loading } = props
  const [str, setStr] = useState<string>(mapping?.ExternalClientId || '')
  const externalClients = useTypedAppSelector(intouchSelectors.extClients)
  const dispatch = useDispatch()

  const updateExternalClient = () => {
    if (!str || !mapping) return

    const duplicate = externalClients.filter(e => e === str);
    if (duplicate.length > 0) {
      setMessage({ message: 'This external client mapping already exists', severity: 'warning', clear: null })
      return
    }
    else
      dispatch(intouchAction.updateProviderMapping({
        Provider: mapping.Provider,
        Username: mapping.Username,
        ProviderClientMappingId: mapping.ProviderClientMappingId,
        HasMapping: mapping.HasMapping,
        ExternalClientId: str
      }))
  }

  return <Dialog maxWidth={false} style={{ width: '100%' }} PaperProps={{ style: { width: 420 } }} open={open} onClose={() => setOpen(false)}>
    <DialogTitle>External Client</DialogTitle>
    <DialogContent>
    <TextField className='external-field' placeholder='External Client' value={str} onChange={e => setStr(e.target.value)} size='small' fullWidth/>
    </DialogContent>
    <DialogActions>
      <Button size='small' disabled={!str || loading} onClick={() => updateExternalClient()}>Save</Button>
      <Button size='small' onClick={() => setOpen(false)}>Cancel</Button>
    </DialogActions>
  </Dialog>
})

type AutoRpmNameProptypes = {
  defaultName?: string;
  open: boolean;
  setOpen: (arg: boolean) => void;
  save: (arg: string) => void;
}

export const AutoRpmTextField: React.FC<AutoRpmNameProptypes & { className?: string }> = (props) => {
  const { className, defaultName, open, setOpen, save } = props
  const [name, setName] = useState(defaultName || '')

  return <Dialog className={className} maxWidth={false} style={{ width: '100%' }} PaperProps={{ style: { width: 420 } }} open={open} onClose={() => setOpen(false)}>
    <DialogTitle>Auto RPM Name Edit</DialogTitle>
    <DialogContent>
      <TextField className='rpm-name-box' placeholder='RPM Name' value={name} onChange={e => setName(e.target.value)} size='small' fullWidth/>
    </DialogContent>
    <DialogActions>
      <Button size='small' disabled={!name} onClick={() => save(name)}>Save</Button>
      <Button size='small' onClick={() => setOpen(false)}>Cancel</Button>
    </DialogActions>
  </Dialog>
}

type SchedulerProptypes = {
  scheduler?: AsyncProcessState<API_NS.AutoRpmSchedulerRes>;
  open: boolean;
  setOpen: (arg: boolean) => void;
  onSave: (str: string, startDate: string, endDate: string | null, timezone: string) => void,
  onDisable: (tz: string) => void,
  onEnable: (tz: string) => void,
  loading?: boolean
}

export const SchedulerDialog: React.FC<SchedulerProptypes & { className?: string }> = (props) => {
  const { className, scheduler, open, setOpen, onEnable, onDisable, onSave, loading } = props

  return <Dialog className={className} fullWidth maxWidth='md' open={open} onClose={() => setOpen(false)}>
    <DialogContent style={{ display: 'flex', justifyContent: 'center', padding: '14px 12px', maxHeight: '80%', width: '100%' }}>
      {scheduler?.loading || loading ? <div style={{ display: 'flex', alignItems: 'center' }}><CircularProgress size={30} />&nbsp;&nbsp;&nbsp; Please wait...</div>
      : <CronSchedulerComponent
          scheduler={scheduler?.value?.[0]}
          onSubmit={onSave}
          onDisable={onDisable}
          onEnable={onEnable}
          submitLoading={scheduler?.loading}
          onClose={() => setOpen(false)}
      />}
    </DialogContent>
  </Dialog>
}

export const deleteAutoRpmDialog = (openDelete, setOpenDelete, deleteRpm) => <Dialog fullWidth open={openDelete} onClose={() => setOpenDelete(false)}>
  <DialogContent style={{ fontSize: 15 }}>Are you sure you want to delete this Auto RPM?</DialogContent>
  <DialogActions>
    <Button size='small' onClick={() => { setOpenDelete(false); deleteRpm() }}>Confirm</Button>
    <Button size='small' onClick={() => setOpenDelete(false)}>Cancel</Button>
  </DialogActions>
</Dialog>

type AddUserProptypes = {
  hasAccess: boolean;
}

export const AddDemoUserContainer: React.FC<AddUserProptypes & { className?: string }> = (props) => {
  const {className, hasAccess} = props
  const [account, setAccount] = useState<{acc: API_NS.Accounts, type: 'customer'} | undefined>(undefined)
  const [demoClientStr, setDemoClientStr] = useState<string>('')
  const demoUsers = useTypedAppSelector(s => s.intouchState.demoUsers)
  const [clear, setClear] = useState<boolean>(false)
  const { setMessage } = useContext(SnackBarContext)
  const dispatch = useDispatch()

  const selectAccount = (acc, type) => setAccount({ acc, type })
  
  const resetClear = () => setClear(false)

  const addDemoUser = () => {
    if (!account || !demoClientStr) return

    const duplicate = (demoUsers?.value || []).filter(d => d.OriginalClientCode === account.acc?.ClientCode);
    if (duplicate.length > 0) {
      setMessage({ message: 'This client has already been set up', severity: 'warning', clear: null })
      return
    }
    
    setMessage({ message: 'Please allow at least 1 minute for the process to complete', severity: 'info', clear: null })
    dispatch(intouchAction.addDemoUser({ ClientId: account.acc.ClientCode , DemoClientId: demoClientStr }))
  }

  return <div className={className}>
    <div className='add-user-div'>
      <ClientSearchComponent selectedAccount={account} selectAccountHandler={selectAccount} clear={clear} resetClear={resetClear} disabled={hasAccess} />
      <ArrowRightIcon className='arrow-icon' size={25} />
      <TextField
        placeholder='Demo Client Code'
        inputProps={{ style: { fontSize: 14, minWidth: 200 } }}
        size='small'
        margin='dense'
        value={demoClientStr || ''}
        onChange={e => setDemoClientStr(e.target.value)}
      />
      <Button className='add-button' color='primary' variant='contained' size='small' onClick={addDemoUser} disabled={!demoClientStr || !account}>Sync User Data</Button>
    </div>
  </div>
}

type AddProviderMappingProptypes = {
  hasAccess: boolean;
}

export const AddProviderMappingContainer: React.FC<AddProviderMappingProptypes & { className?: string }> = (props) => {
  const {className, hasAccess} = props
  const [account, setAccount] = useState<{acc: API_NS.Accounts, type: 'customer'} | undefined>(undefined)
  const [extClientStr, setExtClientStr] = useState<string>('')
  const [selectedProvider, setSelectedProvider] = useState<API_NS.Providers>()
  const extClients = useTypedAppSelector(intouchSelectors.extClients)
  const providers = useTypedAppSelector(s => s.intouchState.providers)
  const addMappingStatus = useTypedAppSelector(s => s.intouchState.addProviderMappingStatus)
  const [open, setOpen] = useState<boolean>(false)
  const [clear, setClear] = useState<boolean>(false)
  const { setMessage } = useContext(SnackBarContext)
  const providerRef = useRef<HTMLButtonElement>(null)
  const dispatch = useDispatch()

  const selectAccount = (acc, type) => setAccount({ acc, type })
  
  const resetClear = () => setClear(false)

  const addMapping = () => {
    if (!account || !extClientStr || !selectedProvider) return

    const duplicate = extClients.filter(e => e === extClientStr);
    if (duplicate.length > 0) {
      setMessage({ message: 'This external client mapping already exists', severity: 'warning', clear: null })
      return
    }
    else {
      dispatch(intouchAction.addProviderMapping({
        ExternalClientId: extClientStr,
        Provider: selectedProvider.ProviderCode,
        Username: account.acc.ClientCode,
        ProviderClientMappingId: 0,
        HasMapping: false
      }))
    }
  }

  useEffect(() => {
    if (addMappingStatus?.message?.level === 'success') {
      setClear(true)
      setAccount(undefined)
      setExtClientStr('')
      setSelectedProvider(undefined)
    }
  }, [addMappingStatus?.message])

  return <div className={className}>
    <div className='add-mapping-div'>
      <TextField
        placeholder='External Client Id'
        inputProps={{ style: { fontSize: 14, minWidth: 180 } }}
        size='small'
        margin='dense'
        value={extClientStr || ''}
        onChange={e => setExtClientStr(e.target.value)}
      />
      <ArrowRightIcon className='arrow-icon' size={25} />
      <ClientSearchComponent selectedAccount={account} selectAccountHandler={selectAccount} clear={clear} resetClear={resetClear} disabled={hasAccess} />
      <Button
        className='providers-dropdown'
        ref={providerRef}
        color='secondary'
        variant='outlined'
        size='small'
        onClick={() => setOpen(!open)}
        endIcon={open ? <ArrowDropUp /> : <ArrowDropDown />}
        disabled={hasAccess}
      >
        {(selectedProvider?.ProviderName || '').length > 0 ? selectedProvider?.ProviderName : 'Choose Provider'}
      </Button>
      <StyledPopper open={open} anchorEl={providerRef.current} placement='bottom-start' style={{ minWidth: providerRef.current?.offsetWidth || '100%' }} transition>
        {({ TransitionProps }) => <Grow {...TransitionProps} timeout={200}>
          <Paper sx={{ bgcolor: 'white' }}>
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <MenuList autoFocusItem style={{ maxHeight: 300, overflowY: 'auto', padding: '4px 0' }} variant='selectedMenu'>
                {(providers?.value || []).map((p, i) => (
                  <MenuItem key={i} style={{ fontSize: 14 }} value={p.ProviderCode} onClick={() => { setSelectedProvider(p); setOpen(false)}} selected={(selectedProvider?.ProviderName || '').includes(p.ProviderName)}>
                    {p.ProviderName}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>}
      </StyledPopper>
      <Button className='add-button' color='primary' variant='contained' size='small' onClick={addMapping} disabled={!extClientStr || !account || !selectedProvider}>Add Mapping</Button>
    </div>
  </div>
}