import { TIntouchAction } from "../actions/intouch.actions"
import { Reducer } from "redux"
import { API_NS } from "../../lib/api/endpoints/endpoint.types"
import { TStateAction } from "../actions/actions"
import { AsyncProcessState } from "../types/shared-state-types"

export type intouchReducerState = {
  uom?: AsyncProcessState<API_NS.UOM[]>;
  uomMappings?: AsyncProcessState<API_NS.UOMMaps[]>;
  demoUsers?: AsyncProcessState<API_NS.DemoUsers[]>;
  apiCustomers?: AsyncProcessState<API_NS.ApiCustomers[]>;
  clientStatus?: { loading?: boolean, message?: { level: 'success' | 'error' | 'info' | 'warning', msg: string } };
  feedLibrary?: AsyncProcessState<API_NS.FeedLibrary>;
  regions?: AsyncProcessState<API_NS.Regions[]>;

  providers?: AsyncProcessState<API_NS.Providers[]>;
  providerMappings?: AsyncProcessState<API_NS.ProviderMappings[]>;
  addProviderMappingStatus?: { loading?: boolean, message?: { level: 'success' | 'error' | 'info' | 'warning', msg: string } };
  updateProviderMappingStatus?: { loading?: boolean, message?: { level: 'success' | 'error' | 'info' | 'warning', msg: string } };

  autoRpmList?: AsyncProcessState<API_NS.AutoRpms[]>;
  autoRpmClients?: {external: string, internal: string} | null;
  autoRpmConfigs?: AsyncProcessState<API_NS.AutoRpmConfigs[]>;
  addAutoRpmStatus?: { autoRpmId: number, loading?: boolean, message?: { level: 'success' | 'error' | 'info' | 'warning', msg: string } };

  autoRpmScheduler?: AsyncProcessState<API_NS.AutoRpmSchedulerRes>;
  autoRpmSchedulerStatus?: { loading?: boolean, message?: { level: 'success' | 'error' | 'info' | 'warning', msg: string } };

  ddwScheduler?: AsyncProcessState<API_NS.DDWSchedulerRes>;
  ddwSchedulerStatus?: { loading?: boolean, message?: { level: 'success' | 'error' | 'info' | 'warning', msg: string } };

  milkPens?: AsyncProcessState<{ PenDescription: string }[]>;
  feedPens?: AsyncProcessState<{ PenDescription: string, PenId: number }[]>;

  accuracyReports?: AsyncProcessState<{ReportId: number, ReportName: string}[]>;
  reportContractInclusions?: AsyncProcessState<API_NS.ReportContractInclusion[]>;
  intouchContractMarkets?:  AsyncProcessState<API_NS.IntouchContractMarket[]>;
  markets?:  AsyncProcessState<{Key: string, Value: string}[]>;
  selectedMarket: {Key: string, Value: string};

  accuracyClients?: AsyncProcessState<API_NS.AccuracyClient[]>;
  reportConfigExclusions?: AsyncProcessState<API_NS.ReportConfigExclusion[]>;
  reportConfigInclusions?: AsyncProcessState<API_NS.ReportConfigInclusion[]>;
  mailRegions?: AsyncProcessState<API_NS.MailRegions[]>;
}

const defaultValue: intouchReducerState = {
  selectedMarket: {Key: 'All', Value: 'All'}
}

const resetState = JSON.parse(JSON.stringify(defaultValue))

export const intouchReducer: Reducer<intouchReducerState, TIntouchAction | TStateAction> = (state = defaultValue, action) => {
  switch (action.type) {
    case "REQUEST_UOM":
      return {
        ...state,
        uom: { loading: true, mssage: undefined }
      };
    case "REQUEST_UOM_SUCCESS":
      return {
        ...state,
        uom: { value: action.payload, loading: false, loaded: true, mssage: undefined }
      };
    case "REQUEST_UOM_FAILED":
      return {
        ...state,
        uom: { value: [], loading: false, loaded: false, mssage: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "REQUEST_UOM_MAPPINGS":
      return {
        ...state,
        uomMappings: { loading: true, message: undefined }
      };
    case "REQUEST_UOM_MAPPINGS_SUCCESS":
      return {
        ...state,
        uomMappings: { value: action.payload, loading: false, loaded: true, message: undefined }
      };
    case "REQUEST_UOM_MAPPINGS_FAILED":
      return {
        ...state,
        uomMappings: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "ADD_UOM_MAPPING":
      return {
        ...state,
        uomMappings: { ...state.uomMappings, loading: true, message: undefined }
      };
    case "ADD_UOM_MAPPING_SUCCESS":
      return {
        ...state,
        uomMappings: { ...state.uomMappings, value: [...state.uomMappings?.value || [], action.payload], loading: false, loaded: true, message: {level: 'success', msg: 'UOM Mapping has been added'} }
      };
    case "ADD_UOM_MAPPING_FAILED":
      return {
        ...state,
        uomMappings: { ...state.uomMappings, loading: false, loaded: false, message: { level: 'error', msg: 'A problem occurred while adding new UOM mapping'} }
      };

    case "UPDATE_UOM_MAPPING":
      return {
        ...state,
        uomMappings: { ...state.uomMappings, loading: true, message: undefined }
      };
    case "UPDATE_UOM_MAPPING_SUCCESS":
      return {
        ...state,
        uomMappings: {
          ...state.uomMappings,
          value: (state.uomMappings?.value || []).map(u => {
            if (u.UnitOfMeasureClientMappingId === action.payload.UnitOfMeasureClientMappingId)
              u.UnitOfMeasureId = action.payload.UnitOfMeasureId
            return u
          }),
          loading: false,
          loaded: true,
          message: {level: 'success', msg: 'UOM Mapping has been updated'}
        }
      };
    case "UPDATE_UOM_MAPPING_FAILED":
      return {
        ...state,
        uomMappings: { ...state.uomMappings, loading: true, loaded: false, message: { level: 'error', msg: 'A problem occurred while updating UOM mapping'} }
      };

    case "DELETE_UOM_MAPPING":
      return {
        ...state,
        uomMappings: { ...state.uomMappings, loading: true, message: undefined }
      };
    case "DELETE_UOM_MAPPING_SUCCESS":
      return {
        ...state,
        uomMappings: {
          ...state.uomMappings,
          value: (state.uomMappings?.value || []).filter(u => u.UnitOfMeasureClientMappingId !== parseInt(action.payload.mapId)),
          loading: false,
          loaded: true,
          message: {level: 'success', msg: 'UOM Mapping has been delete'}
        },
      };
    case "DELETE_UOM_MAPPING_FAILED":
      return {
        ...state,
        uomMappings: { ...state.uomMappings, loading: true, loaded: false, message: {level: 'error', msg: 'A problem occurred while deleting UOM mapping'} }
      };

    case "REQUEST_DEMO_USERS":
      return {
        ...state,
        demoUsers: { loading: true, message: undefined }
      };
    case "REQUEST_DEMO_USERS_SUCCESS":
      return {
        ...state,
        demoUsers: { value: action.payload, loading: false, loaded: true, message: undefined }
      };
    case "REQUEST_DEMO_USERS_FAILED":
      return {
        ...state,
        demoUsers: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "ADD_DEMO_USER":
      return {
        ...state,
        demoUsers: { ...state.demoUsers, loading: true, message: undefined }
      };
    case "ADD_DEMO_USER_SUCCESS":
      return {
        ...state,
        demoUsers: { ...state.demoUsers, loading: false, loaded: true, message: {level: 'info', msg: 'Running Add Demo User Pipeline...'} },
      };
    case "ADD_DEMO_USER_FAILED":
      return {
        ...state,
        demoUsers: { ...state.demoUsers, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while adding new demo user'} }
      };

    case "SYNC_DEMO_USER":
      return {
        ...state,
        demoUsers: { ...state.demoUsers, loading: true, message: undefined }
      };
    case "SYNC_DEMO_USER_SUCCESS":
      return {
        ...state,
        demoUsers: { ...state.demoUsers, loading: false, loaded: true, message: {level: 'info', msg: 'Running Sync Demo User Pipeline...'} },
      };
    case "SYNC_DEMO_USER_FAILED":
      return {
        ...state,
        demoUsers: { ...state.demoUsers, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while syncing demo user'} }
      };

    case "DELETE_DEMO_USER":
      return {
        ...state,
        demoUsers: { ...state.demoUsers, loading: true, message: undefined }
      };
    case "DELETE_DEMO_USER_SUCCESS":
      return {
        ...state,
        demoUsers: {
          ...state.demoUsers,
          value: (state.demoUsers?.value || []).filter(d => d.DemoClientCode !== action.payload.clientid),
          loading: false,
          loaded: true,
          message: {level: 'success', msg: 'Demo User has been deleted'}
        }
      };
    case "DELETE_DEMO_USER_FAILED":
      return {
        ...state,
        demoUsers: { ...state.demoUsers, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while deleting demo user'} }
      };

    case "REQUEST_API_CUSTOMERS":
      return {
        ...state,
        apiCustomers: { loading: true, message: undefined }
      };
    case "REQUEST_API_CUSTOMERS_SUCCESS":
      return {
        ...state,
        apiCustomers: { value: action.payload, loading: false, loaded: true, message: undefined }
      };
    case "REQUEST_API_CUSTOMERS_FAILED":
      return {
        ...state,
        apiCustomers: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "ADD_API_CUSTOMER":
      return {
        ...state,
        apiCustomers: { ...state.apiCustomers, loading: true, message: undefined }
      };
    case "ADD_API_CUSTOMER_SUCCESS":
      const res = Object.keys(action.payload || {}).length > 0 
      ? { LoginId: action.payload.DatapigApiLoginId, Login: action.payload.UserName, IsEnabled: action.payload.IsEnabled, Clients: action.payload.Client} : action.payload
      return {
        ...state,
        apiCustomers: {
          ...state.apiCustomers,
          value: [...state.apiCustomers?.value || [], res],
          loading: false,
          loaded: true,
          message: {level: 'success', msg: 'Customer has been added'}
        }
      };
    case "ADD_API_CUSTOMER_FAILED":
      return {
        ...state,
        apiCustomers: { ...state.apiCustomers, loading: false, loaded:false, message: {level: 'error', msg: 'A problem occurred while adding new customer'} }
      };

    case "UPDATE_API_CUSTOMER":
      return {
        ...state,
        apiCustomers: { ...state.apiCustomers, loading: true, message: undefined }
      };
    case "UPDATE_API_CUSTOMER_SUCCESS":
      return {
        ...state,
        apiCustomers: { ...state.apiCustomers, value: [...(state.apiCustomers?.value || []), action.payload], loading: false, loaded: true, message: {level: 'success', msg: 'Customer has been updated'} }
      };
    case "UPDATE_API_CUSTOMER_FAILED":
      return {
        ...state,
        apiCustomers: { ...state.apiCustomers, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while updating customer'} }
      };

    case "UPDATE_API_CUSTOMER_ENABLED":
      return {
        ...state,
        apiCustomers: { ...state.apiCustomers, loading: true, message: undefined }
      };
    case "UPDATE_API_CUSTOMER_ENABLED_SUCCESS":
      return {
        ...state,
        apiCustomers: {
          ...state.apiCustomers,
          value: (state.apiCustomers?.value || []).map(c => {
            return c.LoginId === action.payload.LoginId ? {...c, IsEnabled: action.payload.IsEnabled} : c
          }),
          loading: false,
          loaded: true,
          message: {level: 'success', msg: 'Customer has been updated'}
        }
      };
    case "UPDATE_API_CUSTOMER_ENABLED_FAILED":
      return {
        ...state,
        apiCustomers: { ...state.apiCustomers, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while updating customer'} }
      };

    case "ADD_API_CLIENT":
      return {
        ...state,
        clientStatus: { loading: true, message: undefined }
      };
    case "ADD_API_CLIENT_SUCCESS":
      const newClient = { Id: action.payload.res.DatapigApiLoginClientId, LoginId: action.payload.res.DatapigApiLoginId, Client: action.payload.req.Client}
      return {
        ...state,
        clientStatus: { loading: false, message: {level: 'success', msg: 'Client has been added'} },
        apiCustomers: {
          ...state.apiCustomers,
          value: (state.apiCustomers?.value || []).map(c => { return c.LoginId === action.payload.req.LoginId ? {...c, Clients: [...c.Clients, newClient]} : c} ),
        }
      };
    case "ADD_API_CLIENT_FAILED":
      return {
        ...state,
        clientStatus: { loading: false, message: {level: 'error', msg: 'A problem occurred while adding new client'} },
      };
  
    case "DELETE_API_CLIENT":
      return {
        ...state,
        clientStatus: { loading: true, message: undefined }
      };
    case "DELETE_API_CLIENT_SUCCESS":
      return {
        ...state,
        clientStatus: { loading: false, message: {level: 'success', msg: 'Client has been deleted'} },
        apiCustomers: {
          ...state.apiCustomers,
          value: (state.apiCustomers?.value || []).map(c => {
            return c.LoginId === action.payload.data.LoginId ? {...c, Clients: c.Clients.filter(cl => cl.Id !== action.payload.id)} : c
          })
        }
      };
    case "DELETE_API_CLIENT_FAILED":
      return {
        ...state,
        clientStatus: { loading: false, message: {level: 'error', msg: 'A problem occurred while deleting client'} },
      };

    case "REQUEST_FEED_LIBRARY":
      return {
        ...state,
        feedLibrary: { ...state.feedLibrary, loading: true, message: undefined }
      };
    case "REQUEST_FEED_LIBRARY_SUCCESS":
      return {
        ...state,
        feedLibrary: { value: action.payload, loading: false, loaded: true, message: undefined }
      };
    case "REQUEST_FEED_LIBRARY_FAILED":
      return {
        ...state,
        feedLibrary: { value: [], loading: false, loaded: false, message: {level: 'error', msg: action.payload || 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "UPDATE_FEED_LIBRARY":
      return {
        ...state,
        feedLibrary: { ...state.feedLibrary, loading: true, message: undefined }
      };
    case "UPDATE_FEED_LIBRARY_SUCCESS":
      return {
        ...state,
        feedLibrary: { ...state.feedLibrary, loading: false, loaded: true, message: {level: 'success', msg: 'Sync Request Accepted - Accounts should be updated in less than a minute'} }
      };
    case "UPDATE_FEED_LIBRARY_FAILED":
      return {
        ...state,
        feedLibrary: { ...state.feedLibrary, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while updating accounts'} }
      };

    case "SUBMIT_FEED_LIBRARY":
      return {
        ...state,
        feedLibrary: { ...state.feedLibrary, loading: true, message: undefined }
      };
    case "SUBMIT_FEED_LIBRARY_SUCCESS":
      return {
        ...state,
        feedLibrary: { ...state.feedLibrary, loading: false, loaded: true, message: {level: 'success', msg: 'This account has been updated to region'} }
      };
    case "SUBMIT_FEED_LIBRARY_FAILED":
      return {
        ...state,
        feedLibrary: { ...state.feedLibrary, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while updating feed library'} }
      };

    case "REQUEST_REGIONS":
      return {
        ...state,
        regions: { loading: true, message: undefined }
      };
    case "REQUEST_REGIONS_SUCCESS":
      return {
        ...state,
        regions: { value: action.payload, loading: false, loaded: true, message: undefined }
      };
    case "REQUEST_REGIONS_FAILED":
      return {
        ...state,
        regions: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "REQUEST_PROVIDERS":
      return {
        ...state,
        providers: { loading: true, message: undefined }
      };
    case "REQUEST_PROVIDERS_SUCCESS":
      return {
        ...state,
        providers: { value: action.payload, loading: false, loaded: true, message: undefined }
      };
    case "REQUEST_PROVIDERS_FAILED":
      return {
        ...state,
        providers: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "REQUEST_PROVIDER_MAPPINGS":
      return {
        ...state,
        providerMappings: { loading: true, message: undefined }
      };
    case "REQUEST_PROVIDER_MAPPINGS_SUCCESS":
      return {
        ...state,
        providerMappings: { value: action.payload, loading: false, loaded: true, message: undefined }
      };
    case "REQUEST_PROVIDER_MAPPINGS_FAILED":
      return {
        ...state,
        providerMappings: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "ADD_PROVIDER_MAPPING":
      return {
        ...state,
        addProviderMappingStatus: { ...state.addProviderMappingStatus, loading: true, message: undefined }
      };
    case "ADD_PROVIDER_MAPPING_SUCCESS":
      return {
        ...state,
        addProviderMappingStatus: { ...state.addProviderMappingStatus, loading: false, loaded: true, message: {level: 'success', msg: 'Provider mapping has been added'} }
      };
    case "ADD_PROVIDER_MAPPING_FAILED":
      return {
        ...state,
        addProviderMappingStatus: { ...state.addProviderMappingStatus, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while adding provider mapping'} }
      };
  
    case "UPDATE_PROVIDER_MAPPING":
      return {
        ...state,
        updateProviderMappingStatus: { loading: true, message: undefined }
      };
    case "UPDATE_PROVIDER_MAPPING_SUCCESS":
      return {
        ...state,
        providerMappings: {
          ...state.providerMappings,
          value: (state.providerMappings?.value || []).map(p => {
            if (p.ProviderClientMappingId === action.payload.ProviderClientMappingId) p.Username = action.payload.Username
            return p
          }),
        },
        updateProviderMappingStatus: { loading: false, message: {level: 'success', msg: 'Provider mapping has been updated'} },

      };
    case "UPDATE_PROVIDER_MAPPING_FAILED":
      return {
        ...state,
        updateProviderMappingStatus: { loading: false, message: {level: 'error', msg: 'A problem occurred while updating provider mapping'} },
      };

    case "DELETE_PROVIDER_MAPPING":
      return {
        ...state,
        providerMappings: { ...state.providerMappings, loading: true, message: undefined }
      };
    case "DELETE_PROVIDER_MAPPING_SUCCESS":
      return {
        ...state,
        providerMappings: {
          ...state.providerMappings,
          value: (state.providerMappings?.value || []).filter(p => p.ProviderClientMappingId !== parseInt(action.payload)),
          loading: false,
          loaded: true,
          message: {level: 'success', msg: 'Provider mapping has been deleted'}
        }
      };
    case "DELETE_PROVIDER_MAPPING_FAILED":
      return {
        ...state,
        providerMappings: { ...state.providerMappings, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while deleting provider mapping'} }
      };

    case "REQUEST_AUTO_RPMS":
      return {
        ...state,
        autoRpmList: { loading: true, message: undefined }
      };
    case "REQUEST_AUTO_RPMS_SUCCESS":
      return {
        ...state,
        autoRpmList: { value: action.payload, loading: false, loaded: true, message: undefined }
      };
    case "REQUEST_AUTO_RPMS_FAILED":
      return {
        ...state,
        autoRpmList: { value: [], loading: false, loaded: false, message: {level: 'error', msg:'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "ADD_AUTO_RPM":
      return {
        ...state,
        addAutoRpmStatus: { ...state.addAutoRpmStatus, autoRpmId: undefined, loading: true, message: undefined }
      };
    case "ADD_AUTO_RPM_SUCCESS":
      return {
        ...state,
        autoRpmList: { ...state.autoRpmList, value: [...(state.autoRpmList?.value || []), action.payload] },
        addAutoRpmStatus: { ...state.addAutoRpmStatus, autoRpmId: action.payload.AutoRpmId, loading: false, loaded: true, message: {level: 'success', msg: 'Auto RPM has been added'} }
      };
    case "ADD_AUTO_RPM_FAILED":
      return {
        ...state,
        addAutoRpmStatus: { ...state.addAutoRpmStatus, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while adding new Auto RPM'} }
      };

    case "UPDATE_AUTO_RPM":
      return {
        ...state,
        autoRpmList: { ...state.autoRpmList, loading: true, message: undefined }
      };
    case "UPDATE_AUTO_RPM_SUCCESS":
      return {
        ...state,
        autoRpmList: {
          ...state.autoRpmList,
          value: (state.autoRpmList?.value || []).map(p => {
            if (p.AutoRpmId === action.payload.AutoRpmId) p.RpmTitle = action.payload.RpmTitle
            return p
          }),
          loading: false,
          loaded: true,
          message: {level: 'success', msg: 'Auto RPM has been updated'}
        },
      };
    case "UPDATE_AUTO_RPM_FAILED":
      return {
        ...state,
        autoRpmList: { ...state.autoRpmList, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while updating Auto RPM'} }
      };

    case "DELETE_AUTO_RPM":
      return {
        ...state,
        autoRpmList: { ...state.autoRpmList, loading: true, message: undefined }
      };
    case "DELETE_AUTO_RPM_SUCCESS":
      return {
        ...state,
        autoRpmList: {
          ...state.autoRpmList,
          value: (state.autoRpmList?.value || []).filter(p => p.AutoRpmId !== parseInt(action.payload.rpmId)),
          loading: false,
          loaded: true,
          message: {level: 'success', msg: 'Auto RPM has been deleted'}
        }
      };
    case "DELETE_AUTO_RPM_FAILED":
      return {
        ...state,
        autoRpmList: { ...state.autoRpmList, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while deleting Auto RPM'} }
      };

    case "REQUEST_AUTO_RPM_CONFIGS":
      return {
        ...state,
        autoRpmConfigs: { loading: true, message: undefined }
      };
    case "REQUEST_AUTO_RPM_CONFIGS_SUCCESS":
      return {
        ...state,
        autoRpmConfigs: { value: action.payload, loading: false, loaded: true, message: undefined }
      };
    case "REQUEST_AUTO_RPM_CONFIGS_FAILED":
      return {
        ...state,
        autoRpmConfigs: { value: [], loading: false, loaded: false, message: {level: 'error', msg: action.payload || 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "ADD_EXTERNAL_AUTO_RPM_CONFIG":
      return {
        ...state,
        autoRpmConfigs: { ...state.autoRpmConfigs, loading: true, message: undefined }
      };
    case "ADD_EXTERNAL_AUTO_RPM_CONFIG_SUCCESS":
      return {
        ...state,
        autoRpmConfigs: { ...state.autoRpmConfigs, value: [...(state.autoRpmConfigs?.value || []), action.payload], loading: false, loaded: true, message: {level: 'success', msg: 'New config has been added'} },
      };
    case "ADD_EXTERNAL_AUTO_RPM_CONFIG_FAILED":
      return {
        ...state,
        autoRpmConfigs: { ...state.autoRpmConfigs, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while adding new config'} }
      };

    case "ADD_INTERNAL_AUTO_RPM_CONFIG":
      return {
        ...state,
        autoRpmConfigs: { ...state.autoRpmConfigs, loading: true, message: undefined }
      };
    case "ADD_INTERNAL_AUTO_RPM_CONFIG_SUCCESS":
      return {
        ...state,
        autoRpmConfigs: { ...state.autoRpmConfigs, value: [...(state.autoRpmConfigs?.value || []), action.payload], loading: false, loaded: true, message: {level: 'success', msg: 'New config has been added'} },
      };
    case "ADD_INTERNAL_AUTO_RPM_CONFIG_FAILED":
      return {
        ...state,
        autoRpmConfigs: { ...state.autoRpmConfigs, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while adding new config'} }
      };
      
    case "DELETE_AUTO_RPM_CONFIG":
      return {
        ...state,
        autoRpmConfigs: { ...state.autoRpmConfigs, loading: true, message: undefined }
      };
    case "DELETE_AUTO_RPM_CONFIG_SUCCESS":
      return {
        ...state,
        autoRpmConfigs: {
          ...state.autoRpmConfigs,
          value: (state.autoRpmConfigs?.value || []).filter(v => v.AutoRpmConfigId !== action.payload),
          loading: false,
          loaded: true,
          message: {level: 'success', msg: 'Config has been deleted'}
        },
      };
    case "DELETE_AUTO_RPM_CONFIG_FAILED":
      return {
        ...state,
        autoRpmConfigs: { ...state.autoRpmConfigs, loading: false, loaded: false,message: {level: 'error', msg: 'A problem occurred while deleting config'} }
      };

    case "REQUEST_AUTO_RPM_SCHEDULER":
      return {
        ...state,
        autoRpmScheduler: { loading: true, message: undefined }
      };
    case "REQUEST_AUTO_RPM_SCHEDULER_SUCCESS":
      return {
        ...state,
        autoRpmScheduler: { value: action.payload, loading: false, loaded: true, message: undefined }
      };
    case "REQUEST_AUTO_RPM_SCHEDULER_FAILED":
      return {
        ...state,
        autoRpmScheduler: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "SUBMIT_AUTO_RPM_SCHEDULER":
      return {
        ...state,
        autoRpmSchedulerStatus: { ...state.autoRpmSchedulerStatus, loading: true, message: undefined }
      };
    case "SUBMIT_AUTO_RPM_SCHEDULER_SUCCESS":
      return {
        ...state,
        autoRpmSchedulerStatus: { ...state.autoRpmSchedulerStatus, loading: false, loaded: true, message: {level: 'success', msg: 'Auto RPM Scheduler has been saved'} }
      };
    case "SUBMIT_AUTO_RPM_SCHEDULER_FAILED":
      return {
        ...state,
        autoRpmSchedulerStatus: { ...state.autoRpmSchedulerStatus, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while saving scheduler'} }
      };

    case "REQUEST_DDW_SCHEDULER":
      return {
        ...state,
        ddwScheduler: { loading: true, message: undefined }
      };
    case "REQUEST_DDW_SCHEDULER_SUCCESS":
      return {
        ...state,
        ddwScheduler: { value: action.payload, loading: false, loaded: true, message: undefined }
      };
    case "REQUEST_DDW_SCHEDULER_FAILED":
      return {
        ...state,
        ddwScheduler: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "SUBMIT_DDW_SCHEDULER":
      return {
        ...state,
        ddwSchedulerStatus: { ...state.ddwSchedulerStatus, loading: true, message: undefined }
      };
    case "SUBMIT_DDW_SCHEDULER_SUCCESS":
      return {
        ...state,
        ddwSchedulerStatus: { ...state.ddwSchedulerStatus, loading: false, loaded: true, message: {level: 'success', msg: 'DDW Scheduler has been saved'} }
      };
    case "SUBMIT_DDW_SCHEDULER_FAILED":
      return {
        ...state,
        ddwSchedulerStatus: { ...state.ddwSchedulerStatus, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while saving scheduler'} }
      };
        
    case "REQUEST_FEED_PENS":
      return {
        ...state,
        feedPens: { loading: true, message: undefined }
      };
    case "REQUEST_FEED_PENS_SUCCESS":
      return {
        ...state,
        feedPens: { value: action.payload, loading: false, loaded: true, message: undefined }
      };
    case "REQUEST_FEED_PENS_FAILED":
      return {
        ...state,
        feedPens: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "REQUEST_MILK_PENS":
      return {
        ...state,
        milkPens: { loading: true, message: undefined }
      };
    case "REQUEST_MILK_PENS_SUCCESS":
      return {
        ...state,
        milkPens: { value: action.payload, loading: false, loaded: true, message: undefined }
      };
    case "REQUEST_MILK_PENS_FAILED":
      return {
        ...state,
        milkPens: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case 'SET_AUTO_RPM_USER':
      return {
        ...state,
        autoRpmClients: action.payload
      };
    
    case "REQUEST_ACCURACY_REPORTS":
      return {
        ...state,
        accuracyReports: { loading: true, message: undefined }
      };
    case "REQUEST_ACCURACY_REPORTS_SUCCESS":
      return {
        ...state,
        accuracyReports: { value: action.payload, loading: false, loaded: true, message: undefined }
      };
    case "REQUEST_ACCURACY_REPORTS_FAILED":
      return {
        ...state,
        accuracyReports: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "REQUEST_REPORT_CONTRACT_INCLUSIONS":
      return {
        ...state,
        reportContractInclusions: { loading: true, message: undefined }
      };
    case "REQUEST_REPORT_CONTRACT_INCLUSIONS_SUCCESS":
      return {
        ...state,
        reportContractInclusions: { value: action.payload, loading: false, loaded: true, message: undefined }
      };
    case "REQUEST_REPORT_CONTRACT_INCLUSIONS_FAILED":
      return {
        ...state,
        reportContractInclusions: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "REQUEST_MARKETS":
      return {
        ...state,
        markets: { loading: true, message: undefined }
      };
    case "REQUEST_MARKETS_SUCCESS":
      return {
        ...state,
        markets: {
          value: [...action.payload || [], {Key: 'All', Value: 'All'}].sort((a, b) => a.Value.toLowerCase() > b.Value.toLowerCase() ? 1 : -1),
          loading: false,
          loaded: true,
          message: undefined
        }
      };
    case "REQUEST_MARKETS_FAILED":
      return {
        ...state,
        markets: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "REQUEST_INTOUCH_CONTRACT_MARKETS":
      return {
        ...state,
        intouchContractMarkets: { loading: true, message: undefined }
      };
    case "REQUEST_INTOUCH_CONTRACT_MARKETS_SUCCESS":
      return {
        ...state,
        intouchContractMarkets: {
          value: action.payload.reduce((p, c) => {
            if (!p.some(obj => obj.InTouchProductId === c.InTouchProductId && obj.market === c.market))
              p.push(c)
            return p
          }, state.intouchContractMarkets?.value || []),
          loading: false,
          loaded: true,
          message: undefined
        }
      };
    case "REQUEST_INTOUCH_CONTRACT_MARKETS_FAILED":
      return {
        ...state,
        intouchContractMarkets: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "ADD_REPORT_INCLUSION":
      return {
        ...state,
        reportContractInclusions: { ...state.reportContractInclusions, loading: true, message: undefined }
      };
    case "ADD_REPORT_INCLUSION_SUCCESS":
      return {
        ...state,
        reportContractInclusions: {
          ...state.reportContractInclusions,
          value: [...state.reportContractInclusions?.value || [], action.payload],
          loading: false,
          loaded: true,
          message: {level: 'success', msg: 'Report contract inclusion has been added' }
        },
      };
    case "ADD_REPORT_INCLUSION_FAILED":
      return {
        ...state,
        reportContractInclusions: { ...state.reportContractInclusions, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while adding new report contract inclusion'} }
      };

    case "DELETE_REPORT_INCLUSION":
      return {
        ...state,
        reportContractInclusions: { ...state.reportContractInclusions, loading: true, message: undefined }
      };
    case "DELETE_REPORT_INCLUSION_SUCCESS":
      return {
        ...state,
        reportContractInclusions: {
          ...state.reportContractInclusions,
          value: (state.reportContractInclusions?.value || []).filter(v => v.ReportContractInclusionId !== action.payload),
          loading: false,
          loaded: true,
          message: {level: 'success', msg: 'Report contract inclusion has been deleted' }
        },
      };
    case "DELETE_REPORT_INCLUSION_FAILED":
      return {
        ...state,
        reportContractInclusions: { ...state.reportContractInclusions, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while deleting report contract inclusion'} }
      };

    case 'SET_SELECTED_MARKET':
      return {
        ...state,
        selectedMarket: action.payload
      };

    case "REQUEST_REPORT_CONFIG_EXCLUSIONS":
      return {
        ...state,
        reportConfigExclusions: { loading: true, message: undefined }
      };
    case "REQUEST_REPORT_CONFIG_EXCLUSIONS_SUCCESS":
      return {
        ...state,
        reportConfigExclusions: { value: action.payload, loading: false, loaded: true, message: undefined }
      };
    case "REQUEST_REPORT_CONFIG_EXCLUSIONS_FAILED":
      return {
        ...state,
        reportConfigExclusions: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "ADD_REPORT_EXCLUSION":
      return {
        ...state,
        reportConfigExclusions: { ...state.reportConfigExclusions, loading: true, message: undefined }
      };
    case "ADD_REPORT_EXCLUSION_SUCCESS":
      return {
        ...state,
        reportConfigExclusions: { ...state.reportConfigExclusions, value: [...state.reportConfigExclusions?.value || [], action.payload], loading: false, loaded: true, message: {level: 'success', msg: 'Report config exclusion has been added' } },
      };
    case "ADD_REPORT_EXCLUSION_FAILED":
      return {
        ...state,
        reportConfigExclusions: { ...state.reportConfigExclusions, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while adding new report config exclusion'} }
      };

    case "UPDATE_REPORT_EXCLUSION":
      return {
        ...state,
        reportConfigExclusions: { ...state.reportConfigExclusions, loading: true, message: undefined }
      };
      
    case "UPDATE_REPORT_EXCLUSION_SUCCESS":
      return {
        ...state,
        reportConfigExclusions: {
          ...state.reportConfigExclusions,
          value: (state.reportConfigExclusions?.value || []).map(re => { return re.ReportExclusionId === action.payload.ReportExclusionId ? action.payload : re }),
          loading: false,
          loaded: true,
          message: {level: 'success', msg: 'Report config exclusion has been updated' }
        }
      };
    case "UPDATE_REPORT_EXCLUSION_FAILED":
      return {
        ...state,
        reportConfigExclusions: { ...state.reportConfigExclusions, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while updating report config exclusion'} }
      };

    case "DELETE_REPORT_EXCLUSION":
      return {
        ...state,
        reportConfigExclusions: { ...state.reportConfigExclusions, loading: true, message: undefined }
      };
    case "DELETE_REPORT_EXCLUSION_SUCCESS":
      return {
        ...state,
        reportConfigExclusions: {
          ...state.reportConfigExclusions,
          value: (state.reportConfigExclusions?.value || []).filter(v => v.ReportExclusionId !== action.payload),
          loading: false,
          loaded: true,
          message: {level: 'success', msg: 'Report config exclusion has been deleted' }
        }
      };
    case "DELETE_REPORT_EXCLUSION_FAILED":
      return {
        ...state,
        reportConfigExclusions: { ...state.reportConfigExclusions, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while deleting report config exclusion'} }
      };

    case "REQUEST_ACCURACY_CLIENTS":
      return {
        ...state,
        accuracyClients: { loading: true, message: undefined }
      };
    case "REQUEST_ACCURACY_CLIENTS_SUCCESS":
      return {
        ...state,
        accuracyClients: { value: action.payload.sort((a ,b) => a.Client.toLowerCase() > b.Client.toLowerCase() ? 1 : -1), loading: false, loaded: true, message: undefined }
      };
    case "REQUEST_ACCURACY_CLIENTS_FAILED":
      return {
        ...state,
        accuracyClients: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "REQUEST_REPORT_CONFIG_INCLUSIONS":
      return {
        ...state,
        reportConfigInclusions: { loading: true, message: undefined }
      };
    case "REQUEST_REPORT_CONFIG_INCLUSIONS_SUCCESS":
      return {
        ...state,
        reportConfigInclusions: { value: action.payload, loading: false, loaded: true, message: undefined }
      };
    case "REQUEST_REPORT_CONFIG_INCLUSIONS_FAILED":
      return {
        ...state,
        reportConfigInclusions: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "ADD_REPORT_CONFIG_INCLUSION":
      return {
        ...state,
        reportConfigInclusions: { ...state.reportConfigInclusions, loading: true, message: undefined }
      };
    case "ADD_REPORT_CONFIG_INCLUSION_SUCCESS":
      return {
        ...state,
        reportConfigInclusions: { ...state.reportConfigInclusions, value: [...state.reportConfigInclusions?.value || [], action.payload], loading: false, loaded: true, message: {level: 'success', msg: 'Report config inclusion has been added' } },
      };
    case "ADD_REPORT_CONFIG_INCLUSION_FAILED":
      return {
        ...state,
        reportConfigInclusions: { ...state.reportConfigInclusions, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while adding new report config inclusion'} }
      };

    case "UPDATE_REPORT_CONFIG_INCLUSION":
      return {
        ...state,
        reportConfigInclusions: { ...state.reportConfigInclusions, loading: true, message: undefined }
      };
    case "UPDATE_REPORT_CONFIG_INCLUSION_SUCCESS":
      return {
        ...state,
        reportConfigInclusions: {
          ...state.reportConfigInclusions,
          value: (state.reportConfigInclusions?.value || []).map(v => { return v.ReportInclusionId === action.payload.ReportInclusionId ? action.payload : v }),
          loading: false,
          loaded: true,
          message: {level: 'success', msg: 'Report config inclusion has been updated' }
        },
      };
    case "UPDATE_REPORT_CONFIG_INCLUSION_FAILED":
      return {
        ...state,
        reportConfigInclusions: { ...state.reportConfigInclusions, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while updated report config inclusion'} }
      };
  
    case "DELETE_REPORT_CONFIG_INCLUSION":
      return {
        ...state,
        reportConfigInclusions: { ...state.reportConfigInclusions, loading: true, message: undefined }
      };
    case "DELETE_REPORT_CONFIG_INCLUSION_SUCCESS":
      return {
        ...state,
        reportConfigInclusions: {
          ...state.reportConfigInclusions,
          value: (state.reportConfigInclusions?.value || []).filter(v => v.ReportInclusionId !== action.payload.id),
          loading: false,
          loaded: true,
          message: {level: 'success', msg: 'Report config inclusion has been deleted' }
        },
      };
    case "DELETE_REPORT_CONFIG_INCLUSION_FAILED":
      return {
        ...state,
        reportConfigInclusions: { ...state.reportConfigInclusions, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while deleting report config inclusion'} }
      };

    case "REQUEST_MAIL_REGIONS":
      return {
        ...state,
        mailRegions: { loading: true, message: undefined }
      };
    case "REQUEST_MAIL_REGIONS_SUCCESS":
      return {
        ...state,
        mailRegions: { value: action.payload, loading: false, loaded: true, message: undefined }
      };
    case "REQUEST_MAIL_REGIONS_FAILED":
      return {
        ...state,
        mailRegions: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };
        
    case "CLEAR_UOM_MESSAGE":
      return {
        ...state,
        uom: { ...state.uom, message: undefined }
      };

    case "CLEAR_UOM_MAPPINGS_MESSAGE":
      return {
        ...state,
        uomMappings: { ...state.uomMappings, message: undefined }
      };

    case "CLEAR_DEMO_USERS_MESSAGE":
      return {
        ...state,
        demoUsers: { ...state.demoUsers, message: undefined }
      };

    case "CLEAR_API_CUSTOMERS_MESSAGE":
      return {
        ...state,
        apiCustomers: { ...state.apiCustomers, message: undefined }
      };
  
    case "CLEAR_CLIENT_STATUS_MESSAGE":
      return {
        ...state,
        clientStatus: { ...state.clientStatus, message: undefined }
      };

    case "CLEAR_FEED_LIBRARY_MESSAGE":
      return {
        ...state,
        feedLibrary: { ...state.feedLibrary, message: undefined }
      };
      
    case "CLEAR_REGIONS_MESSAGE":
      return {
        ...state,
        regions: { ...state.regions, message: undefined }
      };

    case "CLEAR_PROVIDERS_MESSAGE":
      return {
        ...state,
        providers: { ...state.providers, message: undefined }
      };

    case "CLEAR_PROVIDER_MAPPINGS":
      return {
        ...state,
        providerMappings: undefined
      };

    case "CLEAR_PROVIDER_MAPPINGS_MESSAGE":
      return {
        ...state,
        providerMappings: { ...state.providerMappings, message: undefined }
      };

    case "CLEAR_ADD_PROVIDER_MAPPING_MESSAGE":
      return {
        ...state,
        addProviderMappingStatus: { ...state.addProviderMappingStatus, message: undefined }
      };

    case "CLEAR_UPDATE_PROVIDER_MAPPING_MESSAGE":
      return {
        ...state,
        updateProviderMappingStatus: { ...state.updateProviderMappingStatus, message: undefined }
      };
      
    case "CLEAR_AUTO_RPMS_MESSAGE":
      return {
        ...state,
        autoRpmList: { ...state.autoRpmList, message: undefined }
      };
  
    case "CLEAR_ADD_AUTO_RPMS_MESSAGE":
      return {
        ...state,
        addAutoRpmStatus: { ...state.addAutoRpmStatus, message: undefined }
      };

    case "CLEAR_AUTO_RPM_CONFIGS_MESSAGE":
      return {
        ...state,
        autoRpmConfigs: { ...state.autoRpmConfigs, message: undefined }
      }
    
    case "CLEAR_AUTO_RPM_SCHEDULER_MESSAGE":
      return {
        ...state,
        autoRpmScheduler: { ...state.autoRpmScheduler, message: undefined }
      }

    case "CLEAR_DDW_SCHEDULER_MESSAGE":
      return {
        ...state,
        ddwScheduler: { ...state.ddwScheduler, message: undefined }
      }
  
    case "CLEAR_MILK_PENS_MESSAGE":
      return {
        ...state,
        milkPens: { ...state.milkPens, message: undefined }
      }

    case "CLEAR_FEED_PENS_MESSAGE":
      return {
        ...state,
        feedPens: { ...state.feedPens, message: undefined }
      }
      
    case "CLEAR_ACCURACY_REPORTS_MESSAGE":
      return {
        ...state,
        accuracyReports: { ...state.accuracyReports, message: undefined }
      }

    case "CLEAR_REPORTS_CONTRACT_INCLUSIONS":
      return {
        ...state,
        reportContractInclusions: undefined
      }
  
    case "CLEAR_REPORTS_CONTRACT_INCLUSIONS_MESSAGE":
      return {
        ...state,
        reportContractInclusions: { ...state.reportContractInclusions, message: undefined }
      }

    case "CLEAR_INTOUCH_CONTRACT_MARKETS_MESSAGE":
      return {
        ...state,
        intouchContractMarkets: { ...state.intouchContractMarkets, message: undefined }
      }
  
    case "CLEAR_MARKETS_MESSAGE":
      return {
        ...state,
        markets: { ...state.markets, message: undefined }
      }
        
    case "CLEAR_ACCURACY_CLIENTS_MESSAGE":
      return {
        ...state,
        accuracyClients: { ...state.accuracyClients, message: undefined }
      }
      
    case "CLEAR_REPORTS_CONFIG_EXCLUSIONS":
      return {
        ...state,
        reportConfigExclusions: undefined
      }
      
    case "CLEAR_CONFIG_EXCLUSIONS_MESSAGE":
      return {
        ...state,
        reportConfigExclusions: { ...state.reportConfigExclusions, message: undefined }
      }

    case "CLEAR_REPORT_CONFIG_INCLUSIONS":
      return {
        ...state,
        reportConfigInclusions: undefined
      }

    case "CLEAR_CONFIG_INCLUSIONS_MESSAGE":
        return {
          ...state,
          reportConfigInclusions: { ...state.reportConfigInclusions, message: undefined }
        }

    case "CLEAR_MAIL_REGIONS_MESSAGE":
      return {
        ...state,
        mailRegions: { ...state.mailRegions, message: undefined }
      }

    case "CLEAR_SUBMIT_AUTO_RPM_SCHEDULER_MESSAGE":
      return {
        ...state,
        autoRpmSchedulerStatus: { ...state.autoRpmSchedulerStatus, message: undefined }
      }

    case "CLEAR_SUBMIT_DDW_SCHEDULER_MESSAGE":
      return {
        ...state,
        ddwSchedulerStatus: { ...state.ddwSchedulerStatus, message: undefined }
      }

    case 'RESET_STATE':
      return {
        ...resetState
      }
  }
  return state
}