import { API_NS } from '../../lib/api/endpoints/endpoint.types'

export const intouchAction = {

  requestUom: () => {
    return { type: "REQUEST_UOM" as const };
  },
  requestUomSuccess: (payload: API_NS.UOM[]) => {
    return { payload, type: "REQUEST_UOM_SUCCESS" as const };
  },
  requestUomFailed: (payload: string) => {
    return { payload, type: "REQUEST_UOM_FAILED" as const };
  },

  requestUomMappings: () => {
    return { type: "REQUEST_UOM_MAPPINGS" as const };
  },
  requestUomMappingsSuccess: (payload: API_NS.UOMMaps[]) => {
    return { payload, type: "REQUEST_UOM_MAPPINGS_SUCCESS" as const };
  },
  requestUomMappingsFailed: (payload: string) => {
    return { payload, type: "REQUEST_UOM_MAPPINGS_FAILED" as const };
  },

  addUomMapping: (payload: API_NS.UOMMapping) => {
    return { payload, type: "ADD_UOM_MAPPING" as const };
  },
  addUomMappingSuccess: (payload: API_NS.UOMMaps) => {
    return { payload, type: "ADD_UOM_MAPPING_SUCCESS" as const };
  },
  addUomMappingFailed: (payload: string) => {
    return { payload, type: "ADD_UOM_MAPPING_FAILED" as const };
  },

  updateUomMapping: (payload: API_NS.UOMMaps) => {
    return { payload, type: "UPDATE_UOM_MAPPING" as const };
  },
  updateUomMappingSuccess: (payload: API_NS.UOMMaps) => {
    return { payload, type: "UPDATE_UOM_MAPPING_SUCCESS" as const };
  },
  updateUomMappingFailed: (payload: string) => {
    return { payload, type: "UPDATE_UOM_MAPPING_FAILED" as const };
  },

  deleteUomMapping: (payload: { mapId: string }) => {
    return { payload, type: "DELETE_UOM_MAPPING" as const };
  },
  deleteUomMappingSuccess: (payload: { mapId: string }) => {
    return { payload, type: "DELETE_UOM_MAPPING_SUCCESS" as const };
  },
  deleteUomMappingFailed: (payload: string) => {
    return { payload, type: "DELETE_UOM_MAPPING_FAILED" as const };
  },

  requestDemoUsers: () => {
    return { type: "REQUEST_DEMO_USERS" as const };
  },
  requestDemoUsersSuccess: (payload: API_NS.DemoUsers[]) => {
    return { payload, type: "REQUEST_DEMO_USERS_SUCCESS" as const };
  },
  requestDemoUsersFailed: (payload: string) => {
    return { payload, type: "REQUEST_DEMO_USERS_FAILED" as const };
  },

  addDemoUser: (payload: API_NS.DemoUser) => {
    return { payload, type: "ADD_DEMO_USER" as const };
  },
  addDemoUserSuccess: (payload: boolean) => {
    return { payload, type: "ADD_DEMO_USER_SUCCESS" as const };
  },
  addDemoUserFailed: (payload: string) => {
    return { payload, type: "ADD_DEMO_USER_FAILED" as const };
  },

  syncDemoUser: (payload: API_NS.DemoUser) => {
    return { payload, type: "SYNC_DEMO_USER" as const };
  },
  syncDemoUserSuccess: (payload: boolean) => {
    return { payload, type: "SYNC_DEMO_USER_SUCCESS" as const };
  },
  syncDemoUserFailed: (payload: string) => {
    return { payload, type: "SYNC_DEMO_USER_FAILED" as const };
  },

  deleteDemoUser: (payload: { clientid: string }) => {
    return { payload, type: "DELETE_DEMO_USER" as const };
  },
  deleteDemoUserSuccess: (payload: { clientid: string }) => {
    return { payload, type: "DELETE_DEMO_USER_SUCCESS" as const };
  },
  deleteDemoUserFailed: (payload: string) => {
    return { payload, type: "DELETE_DEMO_USER_FAILED" as const };
  },

  requestApiCustomers: () => {
    return { type: "REQUEST_API_CUSTOMERS" as const };
  },
  requestApiCustomersSuccess: (payload: API_NS.ApiCustomers[]) => {
    return { payload, type: "REQUEST_API_CUSTOMERS_SUCCESS" as const };
  },
  requestApiCustomersFailed: (payload: string) => {
    return { payload, type: "REQUEST_API_CUSTOMERS_FAILED" as const };
  },

  addApiCustomer: (payload: { Login: string, Password: string }) => {
    return { payload, type: "ADD_API_CUSTOMER" as const };
  },
  addApiCustomerSuccess: (payload: API_NS.NewApiCustomer) => {
    return { payload, type: "ADD_API_CUSTOMER_SUCCESS" as const };
  },
  addApiCustomerFailed: (payload: string) => {
    return { payload, type: "ADD_API_CUSTOMER_FAILED" as const };
  },

  updateApiCustomer: (payload: API_NS.UpdateApiCustomer) => {
    return { payload, type: "UPDATE_API_CUSTOMER" as const };
  },
  updateApiCustomerSuccess: (payload: boolean) => {
    return { payload, type: "UPDATE_API_CUSTOMER_SUCCESS" as const };
  },
  updateApiCustomerFailed: (payload: string) => {
    return { payload, type: "UPDATE_API_CUSTOMER_FAILED" as const };
  },

  updateApiCustomerEnabled: (payload: { LoginId: number, Login: string, IsEnabled: boolean }) => {
    return { payload, type: "UPDATE_API_CUSTOMER_ENABLED" as const };
  },
  updateApiCustomerEnabledSuccess: (payload: { LoginId: number, Login: string, IsEnabled: boolean }) => {
    return { payload, type: "UPDATE_API_CUSTOMER_ENABLED_SUCCESS" as const };
  },
  updateApiCustomerEnabledFailed: (payload: string) => {
    return { payload, type: "UPDATE_API_CUSTOMER_ENABLED_FAILED" as const };
  },

  addApiClient: (payload: { LoginId: number, Client: string }) => {
    return { payload, type: "ADD_API_CLIENT" as const };
  },
  addApiClientSuccess: (payload: { res: API_NS.ApiClient, req: { LoginId: number, Client: string } }) => {
    return { payload, type: "ADD_API_CLIENT_SUCCESS" as const };
  },
  addApiClientFailed: (payload: string) => {
    return { payload, type: "ADD_API_CLIENT_FAILED" as const };
  },

  deleteApiClient: (payload: { id: number, data: { LoginId: number } }) => {
    return { payload, type: "DELETE_API_CLIENT" as const };
  },
  deleteApiClientSuccess: (payload: { id: number, data: { LoginId: number } }) => {
    return { payload, type: "DELETE_API_CLIENT_SUCCESS" as const };
  },
  deleteApiClientFailed: (payload: string) => {
    return { payload, type: "DELETE_API_CLIENT_FAILED" as const };
  },

  requestFeedLibrary: (payload: { clientId: number }) => {
    return { payload, type: "REQUEST_FEED_LIBRARY" as const };
  },
  requestFeedLibrarySuccess: (payload: API_NS.FeedLibrary) => {
    return { payload, type: "REQUEST_FEED_LIBRARY_SUCCESS" as const };
  },
  requestFeedLibraryFailed: (payload: string) => {
    return { payload, type: "REQUEST_FEED_LIBRARY_FAILED" as const };
  },

  updateFeedLibrary: () => {
    return { type: "UPDATE_FEED_LIBRARY" as const };
  },
  updateFeedLibrarySuccess: (payload: boolean) => {
    return { payload, type: "UPDATE_FEED_LIBRARY_SUCCESS" as const };
  },
  updateFeedLibraryFailed: (payload: string) => {
    return { payload, type: "UPDATE_FEED_LIBRARY_FAILED" as const };
  },

  submitFeedLibrary: (payload: API_NS.FeedLibrary) => {
    return { payload, type: "SUBMIT_FEED_LIBRARY" as const };
  },
  submitFeedLibrarySuccess: (payload: boolean) => {
    return { payload, type: "SUBMIT_FEED_LIBRARY_SUCCESS" as const };
  },
  submitFeedLibraryFailed: (payload: string) => {
    return { payload, type: "SUBMIT_FEED_LIBRARY_FAILED" as const };
  },

  requestRegions: () => {
    return { type: "REQUEST_REGIONS" as const };
  },
  requestRegionsSuccess: (payload: API_NS.Regions[]) => {
    return { payload, type: "REQUEST_REGIONS_SUCCESS" as const };
  },
  requestRegionsFailed: (payload: string) => {
    return { payload, type: "REQUEST_REGIONS_FAILED" as const };
  },

  requestProviders: () => {
    return { type: "REQUEST_PROVIDERS" as const };
  },
  requestProvidersSuccess: (payload: API_NS.Providers[]) => {
    return { payload, type: "REQUEST_PROVIDERS_SUCCESS" as const };
  },
  requestProvidersFailed: (payload: string) => {
    return { payload, type: "REQUEST_PROVIDERS_FAILED" as const };
  },

  requestProviderMappings: () => {
    return { type: "REQUEST_PROVIDER_MAPPINGS" as const };
  },
  requestProviderMappingsSuccess: (payload: API_NS.ProviderMappings[]) => {
    return { payload, type: "REQUEST_PROVIDER_MAPPINGS_SUCCESS" as const };
  },
  requestProviderMappingsFailed: (payload: string) => {
    return { payload, type: "REQUEST_PROVIDER_MAPPINGS_FAILED" as const };
  },

  addProviderMapping: (payload: API_NS.ProviderMapReq) => {
    return { payload, type: "ADD_PROVIDER_MAPPING" as const };
  },
  addProviderMappingSuccess: (payload: API_NS.ProviderMapRes) => {
    return { payload, type: "ADD_PROVIDER_MAPPING_SUCCESS" as const };
  },
  addProviderMappingFailed: (payload: string) => {
    return { payload, type: "ADD_PROVIDER_MAPPING_FAILED" as const };
  },

  updateProviderMapping: (payload: API_NS.ProviderMapReq) => {
    return { payload, type: "UPDATE_PROVIDER_MAPPING" as const };
  },
  updateProviderMappingSuccess: (payload: API_NS.ProviderMapRes) => {
    return { payload, type: "UPDATE_PROVIDER_MAPPING_SUCCESS" as const };
  },
  updateProviderMappingFailed: (payload: string) => {
    return { payload, type: "UPDATE_PROVIDER_MAPPING_FAILED" as const };
  },

  deleteProviderMapping: (payload: { mapId: string }) => {
    return { payload, type: "DELETE_PROVIDER_MAPPING" as const };
  },
  deleteProviderMappingSuccess: (payload: string) => {
    return { payload, type: "DELETE_PROVIDER_MAPPING_SUCCESS" as const };
  },
  deleteProviderMappingFailed: (payload: string) => {
    return { payload, type: "DELETE_PROVIDER_MAPPING_FAILED" as const };
  },

  requestAutoRpms: (payload: { id: string }) => {
    return { payload, type: "REQUEST_AUTO_RPMS" as const };
  },
  requestAutoRpmsSuccess: (payload: API_NS.AutoRpms[]) => {
    return { payload, type: "REQUEST_AUTO_RPMS_SUCCESS" as const };
  },
  requestAutoRpmsFailed: (payload: string) => {
    return { payload, type: "REQUEST_AUTO_RPMS_FAILED" as const };
  },

  addAutoRpm: (payload: API_NS.AddAutoRpm) => {
    return { payload, type: "ADD_AUTO_RPM" as const };
  },
  addAutoRpmSuccess: (payload: API_NS.AutoRpms) => {
    return { payload, type: "ADD_AUTO_RPM_SUCCESS" as const };
  },
  addAutoRpmFailed: (payload: string) => {
    return { payload, type: "ADD_AUTO_RPM_FAILED" as const };
  },

  updateAutoRpm: (payload: API_NS.UpdateAutoRpm) => {
    return { payload, type: "UPDATE_AUTO_RPM" as const };
  },
  updateAutoRpmSuccess: (payload: API_NS.UpdateAutoRpm) => {
    return { payload, type: "UPDATE_AUTO_RPM_SUCCESS" as const };
  },
  updateAutoRpmFailed: (payload: string) => {
    return { payload, type: "UPDATE_AUTO_RPM_FAILED" as const };
  },

  deleteAutoRpm: (payload: { rpmId: string }) => {
    return { payload, type: "DELETE_AUTO_RPM" as const };
  },
  deleteAutoRpmSuccess: (payload: { rpmId: string }) => {
    return { payload, type: "DELETE_AUTO_RPM_SUCCESS" as const };
  },
  deleteAutoRpmFailed: (payload: string) => {
    return { payload, type: "DELETE_AUTO_RPM_FAILED" as const };
  },

  requestAutoRpmConfigs: (payload: { rpmId: string }) => {
    return { payload, type: "REQUEST_AUTO_RPM_CONFIGS" as const };
  },
  requestAutoRpmConfigsSuccess: (payload: API_NS.AutoRpmConfigs[]) => {
    return { payload, type: "REQUEST_AUTO_RPM_CONFIGS_SUCCESS" as const };
  },
  requestAutoRpmConfigsFailed: (payload: string) => {
    return { payload, type: "REQUEST_AUTO_RPM_CONFIGS_FAILED" as const };
  },

  addExternalAutoRpmConfig: (payload: { AutoRpmId: number, ExternalPenReference: string }) => {
    return { payload, type: "ADD_EXTERNAL_AUTO_RPM_CONFIG" as const };
  },
  addExternalAutoRpmConfigSuccess: (payload: API_NS.AutoRpmConfigs) => {
    return { payload, type: "ADD_EXTERNAL_AUTO_RPM_CONFIG_SUCCESS" as const };
  },
  addExternalAutoRpmConfigFailed: (payload: string) => {
    return { payload, type: "ADD_EXTERNAL_AUTO_RPM_CONFIG_FAILED" as const };
  },

  addInternalAutoRpmConfig: (payload: API_NS.InternalAutoRpmConfig) => {
    return { payload, type: "ADD_INTERNAL_AUTO_RPM_CONFIG" as const };
  },
  addInternalAutoRpmConfigSuccess: (payload: API_NS.AutoRpmConfigs) => {
    return { payload, type: "ADD_INTERNAL_AUTO_RPM_CONFIG_SUCCESS" as const };
  },
  addInternalAutoRpmConfigFailed: (payload: string) => {
    return { payload, type: "ADD_INTERNAL_AUTO_RPM_CONFIG_FAILED" as const };
  },

  deleteAutoRpmConfig: (payload: { id: number }) => {
    return { payload, type: "DELETE_AUTO_RPM_CONFIG" as const };
  },
  deleteAutoRpmConfigSuccess: (payload: number) => {
    return { payload, type: "DELETE_AUTO_RPM_CONFIG_SUCCESS" as const };
  },
  deleteAutoRpmConfigFailed: (payload: string) => {
    return { payload, type: "DELETE_AUTO_RPM_CONFIG_FAILED" as const };
  },

  requestAutoRpmScheduler: (payload: { id: string }) => {
    return { payload, type: "REQUEST_AUTO_RPM_SCHEDULER" as const };
  },
  requestAutoRpmSchedulerSuccess: (payload: API_NS.AutoRpmSchedulerRes[]) => {
    return { payload, type: "REQUEST_AUTO_RPM_SCHEDULER_SUCCESS" as const };
  },
  requestAutoRpmSchedulerFailed: (payload: string) => {
    return { payload, type: "REQUEST_AUTO_RPM_SCHEDULER_FAILED" as const };
  },

  submitAutoRpmScheduler: (payload: API_NS.AutoRpmSchedulerReq) => {
    return { payload, type: "SUBMIT_AUTO_RPM_SCHEDULER" as const };
  },
  submitAutoRpmSchedulerSuccess: (payload: boolean) => {
    return { payload, type: "SUBMIT_AUTO_RPM_SCHEDULER_SUCCESS" as const };
  },
  submitAutoRpmSchedulerFailed: (payload: string) => {
    return { payload, type: "SUBMIT_AUTO_RPM_SCHEDULER_FAILED" as const };
  },

  requestDDWScheduler: (payload: { clientId: string }) => {
    return { payload, type: "REQUEST_DDW_SCHEDULER" as const };
  },
  requestDDWSchedulerSuccess: (payload: API_NS.DDWSchedulerRes) => {
    return { payload, type: "REQUEST_DDW_SCHEDULER_SUCCESS" as const };
  },
  requestDDWSchedulerFailed: (payload: string) => {
    return { payload, type: "REQUEST_DDW_SCHEDULER_FAILED" as const };
  },

  submitDDWScheduler: (payload: API_NS.DDWSchedulerReq) => {
    return { payload, type: "SUBMIT_DDW_SCHEDULER" as const };
  },
  submitDDWSchedulerSuccess: (payload: boolean) => {
    return { payload, type: "SUBMIT_DDW_SCHEDULER_SUCCESS" as const };
  },
  submitDDWSchedulerFailed: (payload: string) => {
    return { payload, type: "SUBMIT_DDW_SCHEDULER_FAILED" as const };
  },

  requestFeedPens: (payload: { clientId: string }) => {
    return { payload, type: "REQUEST_FEED_PENS" as const };
  },
  requestFeedPensSuccess: (payload: { PenDescription: string, PenId: number }[]) => {
    return { payload, type: "REQUEST_FEED_PENS_SUCCESS" as const };
  },
  requestFeedPensFailed: (payload: string) => {
    return { payload, type: "REQUEST_FEED_PENS_FAILED" as const };
  },

  requestMilkPens: (payload: { clientId: string }) => {
    return { payload, type: "REQUEST_MILK_PENS" as const };
  },
  requestMilkPensSuccess: (payload: { PenDescription: string }[]) => {
    return { payload, type: "REQUEST_MILK_PENS_SUCCESS" as const };
  },
  requestMilkPensFailed: (payload: string) => {
    return { payload, type: "REQUEST_MILK_PENS_FAILED" as const };
  },

  setAutoRpmUser: (payload: {external: string, internal: string} | null) => {
    return { payload, type: 'SET_AUTO_RPM_USER' as const }
  },

  requestAccuracyReports: () => {
    return { type: "REQUEST_ACCURACY_REPORTS" as const };
  },
  requestAccuracyReportsSuccess: (payload: {ReportId: number, ReportName: string}[]) => {
    return { payload, type: "REQUEST_ACCURACY_REPORTS_SUCCESS" as const };
  },
  requestAccuracyReportsFailed: (payload: string) => {
    return { payload, type: "REQUEST_ACCURACY_REPORTS_FAILED" as const };
  },

  requestReportContractInclusions: (payload: { id: string }) => {
    return { payload, type: "REQUEST_REPORT_CONTRACT_INCLUSIONS" as const };
  },
  requestReportContractInclusionsSuccess: (payload: API_NS.ReportContractInclusion[]) => {
    return { payload, type: "REQUEST_REPORT_CONTRACT_INCLUSIONS_SUCCESS" as const };
  },
  requestReportContractInclusionsFailed: (payload: string) => {
    return { payload, type: "REQUEST_REPORT_CONTRACT_INCLUSIONS_FAILED" as const };
  },

  requestMarkets: () => {
    return { type: "REQUEST_MARKETS" as const };
  },
  requestMarketsSuccess: (payload: {Key: string, Value: string}[]) => {
    return { payload, type: "REQUEST_MARKETS_SUCCESS" as const };
  },
  requestMarketsFailed: (payload: string) => {
    return { payload, type: "REQUEST_MARKETS_FAILED" as const };
  },

  requestIntouchContractMarkets: () => {
    return { type: "REQUEST_INTOUCH_CONTRACT_MARKETS" as const };
  },
  requestIntouchContractMarketsSuccess: (payload: API_NS.IntouchContractMarket[]) => {
    return { payload, type: "REQUEST_INTOUCH_CONTRACT_MARKETS_SUCCESS" as const };
  },
  requestIntouchContractMarketsFailed: (payload: string) => {
    return { payload, type: "REQUEST_INTOUCH_CONTRACT_MARKETS_FAILED" as const };
  },

  addReportInclusion: (payload: API_NS.IntouchInclusion) => {
    return { payload, type: "ADD_REPORT_INCLUSION" as const };
  },
  addReportInclusionSuccess: (payload: API_NS.ReportContractInclusion) => {
    return { payload, type: "ADD_REPORT_INCLUSION_SUCCESS" as const };
  },
  addReportInclusionFailed: (payload: string) => {
    return { payload, type: "ADD_REPORT_INCLUSION_FAILED" as const };
  },

  deleteReportInclusion: (payload: { id: number }) => {
    return { payload, type: "DELETE_REPORT_INCLUSION" as const };
  },
  deleteReportInclusionSuccess: (payload: number) => {
    return { payload, type: "DELETE_REPORT_INCLUSION_SUCCESS" as const };
  },
  deleteReportInclusionFailed: (payload: string) => {
    return { payload, type: "DELETE_REPORT_INCLUSION_FAILED" as const };
  },

  setSelectedMarket: (payload: {Key: string, Value: string}) => {
    return { payload, type: 'SET_SELECTED_MARKET' as const }
  },
  
  requestReportConfigExclusions: (payload: { id: number }) => {
    return { payload, type: "REQUEST_REPORT_CONFIG_EXCLUSIONS" as const };
  },
  requestReportConfigExclusionsSuccess: (payload: API_NS.ReportConfigExclusion[]) => {
    return { payload, type: "REQUEST_REPORT_CONFIG_EXCLUSIONS_SUCCESS" as const };
  },
  requestReportConfigExclusionsFailed: (payload: string) => {
    return { payload, type: "REQUEST_REPORT_CONFIG_EXCLUSIONS_FAILED" as const };
  },  

  addReportExclusion: (payload: API_NS.AddReportConfigExclusion) => {
    return { payload, type: "ADD_REPORT_EXCLUSION" as const };
  },
  addReportExclusionSuccess: (payload: API_NS.ReportConfigExclusion) => {
    return { payload, type: "ADD_REPORT_EXCLUSION_SUCCESS" as const };
  },
  addReportExclusionFailed: (payload: string) => {
    return { payload, type: "ADD_REPORT_EXCLUSION_FAILED" as const };
  },

  updateReportExclusion: (payload: API_NS.ReportConfigExclusion) => {
    return { payload, type: "UPDATE_REPORT_EXCLUSION" as const };
  },
  updateReportExclusionSuccess: (payload: API_NS.ReportConfigExclusion) => {
    return { payload, type: "UPDATE_REPORT_EXCLUSION_SUCCESS" as const };
  },
  updateReportExclusionFailed: (payload: string) => {
    return { payload, type: "UPDATE_REPORT_EXCLUSION_FAILED" as const };
  },

  deleteReportExclusion: (payload: { id: number }) => {
    return { payload, type: "DELETE_REPORT_EXCLUSION" as const };
  },
  deleteReportExclusionSuccess: (payload: number) => {
    return { payload, type: "DELETE_REPORT_EXCLUSION_SUCCESS" as const };
  },
  deleteReportExclusionFailed: (payload: string) => {
    return { payload, type: "DELETE_REPORT_EXCLUSION_FAILED" as const };
  },
  
  requestAccuracyClients: () => {
    return { type: "REQUEST_ACCURACY_CLIENTS" as const };
  },
  requestAccuracyClientsSuccess: (payload: API_NS.AccuracyClient[]) => {
    return { payload, type: "REQUEST_ACCURACY_CLIENTS_SUCCESS" as const };
  },
  requestAccuracyClientsFailed: (payload: string) => {
    return { payload, type: "REQUEST_ACCURACY_CLIENTS_FAILED" as const };
  },  

  requestReportConfigInclusions: (payload: { id: number }) => {
    return { payload, type: "REQUEST_REPORT_CONFIG_INCLUSIONS" as const };
  },
  requestReportConfigInclusionsSuccess: (payload: API_NS.ReportConfigInclusion[]) => {
    return { payload, type: "REQUEST_REPORT_CONFIG_INCLUSIONS_SUCCESS" as const };
  },
  requestReportConfigInclusionsFailed: (payload: string) => {
    return { payload, type: "REQUEST_REPORT_CONFIG_INCLUSIONS_FAILED" as const };
  },  

  addReportConfigInclusion: (payload: API_NS.ReportConfigInclusionReq) => {
    return { payload, type: "ADD_REPORT_CONFIG_INCLUSION" as const };
  },
  addReportConfigInclusionSuccess: (payload: API_NS.ReportConfigInclusion) => {
    return { payload, type: "ADD_REPORT_CONFIG_INCLUSION_SUCCESS" as const };
  },
  addReportConfigInclusionFailed: (payload: string) => {
    return { payload, type: "ADD_REPORT_CONFIG_INCLUSION_FAILED" as const };
  },

  updateReportConfigInclusion: (payload: API_NS.ReportConfigInclusion) => {
    return { payload, type: "UPDATE_REPORT_CONFIG_INCLUSION" as const };
  },
  updateReportConfigInclusionSuccess: (payload: API_NS.ReportConfigInclusion) => {
    return { payload, type: "UPDATE_REPORT_CONFIG_INCLUSION_SUCCESS" as const };
  },
  updateReportConfigInclusionFailed: (payload: string) => {
    return { payload, type: "UPDATE_REPORT_CONFIG_INCLUSION_FAILED" as const };
  },

  deleteReportConfigInclusion: (payload: { id: number }) => {
    return { payload, type: "DELETE_REPORT_CONFIG_INCLUSION" as const };
  },
  deleteReportConfigInclusionSuccess: (payload: { id: number }) => {
    return { payload, type: "DELETE_REPORT_CONFIG_INCLUSION_SUCCESS" as const };
  },
  deleteReportConfigInclusionFailed: (payload: string) => {
    return { payload, type: "DELETE_REPORT_CONFIG_INCLUSION_FAILED" as const };
  },
  
  requestMailRegions: () => {
    return { type: "REQUEST_MAIL_REGIONS" as const };
  },
  requestMailRegionsSuccess: (payload: API_NS.MailRegions[]) => {
    return { payload, type: "REQUEST_MAIL_REGIONS_SUCCESS" as const };
  },
  requestMailRegionsFailed: (payload: string) => {
    return { payload, type: "REQUEST_MAIL_REGIONS_FAILED" as const };
  },  

  clearUomMessage: () => {
    return { type: "CLEAR_UOM_MESSAGE" as const };
  },

  clearUomMappingsMessage: () => {
    return { type: "CLEAR_UOM_MAPPINGS_MESSAGE" as const };
  },

  clearDemoUsersMessage: () => {
    return { type: "CLEAR_DEMO_USERS_MESSAGE" as const };
  },

  clearApiCustomersMessage: () => {
    return { type: "CLEAR_API_CUSTOMERS_MESSAGE" as const };
  },

  clearClientStatusMessage: () => {
    return { type: "CLEAR_CLIENT_STATUS_MESSAGE" as const };
  },
  
  clearFeedLibraryMessage: () => {
    return { type: "CLEAR_FEED_LIBRARY_MESSAGE" as const };
  },

  clearRegionsMessage: () => {
    return { type: "CLEAR_REGIONS_MESSAGE" as const };
  },

  clearProvidersMessage: () => {
    return { type: "CLEAR_PROVIDERS_MESSAGE" as const };
  },

  clearProviderMappings : () => {
    return { type: "CLEAR_PROVIDER_MAPPINGS" as const };
  },

  clearProvidersMappingsMessage: () => {
    return { type: "CLEAR_PROVIDER_MAPPINGS_MESSAGE" as const };
  },

  clearAddProviderMappingMessage: () => {
    return { type: "CLEAR_ADD_PROVIDER_MAPPING_MESSAGE" as const };
  },

  clearUpdateProviderMappingMessage: () => {
    return { type: "CLEAR_UPDATE_PROVIDER_MAPPING_MESSAGE" as const };
  },

  clearAutoRpmsMessage: () => {
    return { type: "CLEAR_AUTO_RPMS_MESSAGE" as const };
  },

  clearAddAutoRpmsMessage: () => {
    return { type: "CLEAR_ADD_AUTO_RPMS_MESSAGE" as const };
  },

  clearAutoRpmConfigsMessage: () => {
    return { type: "CLEAR_AUTO_RPM_CONFIGS_MESSAGE" as const };
  },
  
  clearAutoRpmSchedulerMessage: () => {
    return { type: "CLEAR_AUTO_RPM_SCHEDULER_MESSAGE" as const };
  },

  clearDDWSchedulerMessage: () => {
    return { type: "CLEAR_DDW_SCHEDULER_MESSAGE" as const };
  },

  clearMilkPensMessage: () => {
    return { type: "CLEAR_MILK_PENS_MESSAGE" as const };
  },

  clearFeedPensMessage: () => {
    return { type: "CLEAR_FEED_PENS_MESSAGE" as const };
  },

  clearAccuracyReportsMessage: () => {
    return { type: "CLEAR_ACCURACY_REPORTS_MESSAGE" as const };
  },

  clearReportContractInclusions : () => {
    return { type: "CLEAR_REPORTS_CONTRACT_INCLUSIONS" as const };
  },

  clearReportContractInclusionsMessage: () => {
    return { type: "CLEAR_REPORTS_CONTRACT_INCLUSIONS_MESSAGE" as const };
  },

  clearIntouchContractMarketsMessage: () => {
    return { type: "CLEAR_INTOUCH_CONTRACT_MARKETS_MESSAGE" as const };
  },

  clearMarketsMessage: () => {
    return { type: "CLEAR_MARKETS_MESSAGE" as const };
  },

  clearAccuracyClientsMessage: () => {
    return { type: "CLEAR_ACCURACY_CLIENTS_MESSAGE" as const };
  },

  clearReportConfigExclusions : () => {
    return { type: "CLEAR_REPORTS_CONFIG_EXCLUSIONS" as const };
  },

  clearConfigExclusionsMessage: () => {
    return { type: "CLEAR_CONFIG_EXCLUSIONS_MESSAGE" as const };
  },

  clearReportConfigInclusions : () => {
    return { type: "CLEAR_REPORT_CONFIG_INCLUSIONS" as const };
  },


  clearConfigInclusionsMessage: () => {
    return { type: "CLEAR_CONFIG_INCLUSIONS_MESSAGE" as const };
  },

  clearMailRegionsMessage: () => {
    return { type: "CLEAR_MAIL_REGIONS_MESSAGE" as const };
  },

  clearSubmitAutoRpmSchedulerMessage: () => {
    return { type: "CLEAR_SUBMIT_AUTO_RPM_SCHEDULER_MESSAGE" as const };
  },

  clearSubmitDDWSchedulerMessage: () => {
    return { type: "CLEAR_SUBMIT_DDW_SCHEDULER_MESSAGE" as const };
  },
}

export type TIntouchAction = ReturnType<typeof intouchAction[keyof typeof intouchAction]>