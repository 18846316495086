import { Array as RtArray, Boolean as RtBoolean, Null, Number, Record, String, Undefined } from 'runtypes';
import { DateString } from './custom.runtypes';

export const RpmRecord = Record({
  UserName: String.Or(Null),
  FarmName: String,
  AccountId: Number.Or(Null),
  RpmId: String,
  IsValidated: String,
  IsReviewed: String,
  RpmDate: DateString,
  FCE: Number,
  Protein: Number,
  Fat: Number,
  Margin: Number,
  Yield: Number,
  DMI: Number,
  CowsInMilk: Number,
  DailyCost: Number,
  MilkPrice: Number,
  DaysInMilk: Number,
  ValidatedComment: String.Or(Null).Or(Undefined)
})

export const AutoRpmSchedulerRequest = Record({
  AutoRpmId: Number,
  CronSchedule: String,
  Enabled: RtBoolean.Or(Undefined).Or(Null),
  StartDate: DateString,
  EndDate: DateString.Or(Undefined).Or(Null),
  TimeZone: String.Or(Undefined).Or(Null)
})

export const AutoRpmSchedulerResponse = Record({
  AutoRpmId: Number,
  CronSchedule: String.Or(Undefined).Or(Null),
  ReadableCronSChedule: String.Or(Undefined).Or(Null),
  Enabled: RtBoolean.Or(Undefined).Or(Null),
  StartDate: DateString.Or(Undefined).Or(Null),
  EndDate: DateString.Or(Undefined).Or(Null),
  TimeZone: String.Or(Undefined).Or(Null)
})

export const DDWSchedulerRequest = Record({
  Client: String,
  CronSchedule: String,
  Enabled: RtBoolean.Or(Undefined).Or(Null),
  StartDate: DateString,
  EndDate: DateString.Or(Undefined).Or(Null),
  TimeZone: String.Or(Undefined).Or(Null)
})

export const DDWSchedulerResponse = Record({
  Client: String,
  CronSchedule: String.Or(Undefined).Or(Null),
  ReadableCronSChedule: String.Or(Undefined).Or(Null),
  Enabled: RtBoolean.Or(Undefined).Or(Null),
  StartDate: DateString.Or(Undefined).Or(Null),
  EndDate: DateString.Or(Undefined).Or(Null),
  TimeZone: String.Or(Undefined).Or(Null)
})

export const TimezoneRecord = Record({
  Id: String,
  DisplayName: String,
  StandardName: String,
  DaylightName: String,
  BaseUtcOffset: String,
  SupportsDaylightSavingTime: RtBoolean,
  AdjustmentRules: RtArray(Record({
      DateStart: DateString,
      DateEnd: DateString,
      DaylightDelta: String,
      DaylightTransitionStart: Record({
          TimeOfDay: DateString,
          Month: Number,
          Week: Number,
          Day: Number,
          DayOfWeek: Number,
          IsFixedDateRule: RtBoolean
      }),
      DaylightTransitionEnd: Record({
          TimeOfDay: DateString,
          Month: Number,
          Week: Number,
          Day: Number,
          DayOfWeek: Number,
          IsFixedDateRule: RtBoolean
      }),
      BaseUtcOffsetDelta: String
  })).Or(Null).Or(Undefined),
})

export const ReportInclusionRecord = Record({
  ReportInclusionId: Number.Or(Null).Or(Undefined),
  ReportId: Number,
  ClientId: String,
  MailRegionId: Number.Or(Null).Or(Undefined),
  Email: String.Or(Null).Or(Undefined),
  CC: String.Or(Null).Or(Undefined),
})

export const ReportInclusionRequest = Record({
  ReportId: Number,
  ClientId: String,
  MailRegionId: Number.Or(Null).Or(Undefined),
  Email: String.Or(Null).Or(Undefined),
  CC: String.Or(Null).Or(Undefined),
})

export const ReportExclusionRecord = Record({
  ReportExclusionId: Number.Or(Null).Or(Undefined),
  ReportId: Number,
  ClientId: String,
  ExcludeClient: RtBoolean.Or(Null).Or(Undefined),
  ExcludeNutritionist: RtBoolean.Or(Null).Or(Undefined),
  ExcludeAccountContact: RtBoolean.Or(Null).Or(Undefined),
  ExcludePrimaryContact: RtBoolean.Or(Null).Or(Undefined),
  ExcludeThirdPartyContact: RtBoolean.Or(Null).Or(Undefined)
})

export const AddReportExclusionRecord = Record({
  ReportId: Number,
  ClientId: String,
  ExcludeClient: RtBoolean.Or(Null).Or(Undefined),
  ExcludeNutritionist: RtBoolean.Or(Null).Or(Undefined),
  ExcludeAccountContact: RtBoolean.Or(Null).Or(Undefined),
  ExcludePrimaryContact: RtBoolean.Or(Null).Or(Undefined),
  ExcludeThirdPartyContact: RtBoolean.Or(Null).Or(Undefined)
})

export const UpdateProviderMappingRecord = Record({
  ExternalClientId: String,
  Provider: String,
  ProviderClientMappingId: Number,
  Username: String,
  AutoRpms: RtArray(Record({
    AutoRpmId: Number.Or(Null).Or(Undefined),
    RpmTitle: String.Or(Null).Or(Undefined),
    ProviderClientMappingId: Number.Or(Null).Or(Undefined),
    Enabled: RtBoolean.Or(Null).Or(Undefined),
    AutoRpmConfig: RtArray(Record({
      AutoRpmConfigId: Number.Or(Null).Or(Undefined),
      AutoRpmId: Number.Or(Null).Or(Undefined),
      IsAlltechGroup: RtBoolean.Or(Null).Or(Undefined),
      InternalPenId: Number.Or(Null).Or(Undefined),
      InternalPenName: String.Or(Null).Or(Undefined),
      ExternalPenReference: String.Or(Null).Or(Undefined)
    }))
  }))
})

export const ProviderMappingsRecord = Record({
  ExternalClientId: String,
  Provider: String,
  Username: String,
  AccountName: String,
  Nutritionist: String.Or(Null),
  ProviderClientMappingId: Number,
  HasMapping: RtBoolean
})

export const AutoRpmListRecord = Record({
  AutoRpmConfigs: RtArray(Record({
    AutoRpmConfigId: Number,
    AutoRpmId: Number,
    IsAlltechGroup: RtBoolean,
    InternalPenId: Number,
    InternalPenName: String.Or(Null),
    ExternalPenReference: Number
  })),
  AutoRpmId: Number,
  RpmTitle: String,
  ProviderClientMappingId: Number,
  Enabled: RtBoolean,
  AutoRpmScheduleEnabled: RtBoolean,
  AutoRpmScheduleCron: String.Or(Null).Or(Undefined),
})

export const AutoRpmRecord = Record({
  AutoRpmConfigId: Number,
  AutoRpmId: Number,
  IsAlltechGroup: RtBoolean,
  InternalPenId: Number,
  InternalPenName: String,
  ExternalPenReference: String
})

export const TargetCategoriesRecord = Record({
  C_TARGETCATEGORYID: Number,
  CATEGORYNAME: String,
  is_feeder: RtBoolean,
  is_vertical: RtBoolean,
  is_self_propelled: RtBoolean,
  is_retro_fit: RtBoolean,
  is_spares: RtBoolean,
  is_nutrition: RtBoolean,
  is_reportable: RtBoolean,
  is_market_target: RtBoolean,
  FAMILYNAME: String
})