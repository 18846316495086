import { combineEpics, Epic, } from "redux-observable"
import { TIntouchAction, intouchAction } from "../actions/intouch.actions"
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators"
import { endpoints } from "../../lib/api/endpoints/endpoint_list"
import { of } from "rxjs"
import { TCombinedState } from '../index'
import { epicErrorLogger } from "../../lib/utils/epic-error-logger"

/**
 * Trigger API calls or any other async actions
 */
export const intouchEpic: Epic<TIntouchAction, any, TCombinedState> = combineEpics(

  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_UOM' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.uom.get().pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.requestUomSuccess(epicRes.data.Payload)
          } else {
            return intouchAction.requestUomFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.requestUomFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_UOM_MAPPINGS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.uomMaps.get().pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.requestUomMappingsSuccess(epicRes.data.Payload)
          } else {
            return intouchAction.requestUomMappingsFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.requestUomMappingsFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'ADD_UOM_MAPPING' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.updateUomMapping.post(a.payload).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.addUomMappingSuccess(epicRes.data.Payload)
          } else {
            return intouchAction.addUomMappingFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.addUomMappingFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'UPDATE_UOM_MAPPING' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.updateUomMapping.put(a.payload).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.updateUomMappingSuccess(a.payload)
          } else {
            return intouchAction.updateUomMappingFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.updateUomMappingFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'DELETE_UOM_MAPPING' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.deleteUomMapping.delete({ mapId: a.payload.mapId.toString() }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.deleteUomMappingSuccess(a.payload)
          } else {
            return intouchAction.deleteUomMappingFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.deleteUomMappingFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_DEMO_USERS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.demoAccounts.get().pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.requestDemoUsersSuccess(epicRes.data.Payload)
          } else {
            return intouchAction.requestDemoUsersFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.requestDemoUsersFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'ADD_DEMO_USER' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.demoUser.post(a.payload).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.addDemoUserSuccess(epicRes.data.Payload)
          } else {
            return intouchAction.addDemoUserFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.addDemoUserFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'SYNC_DEMO_USER' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.demoUser.post(a.payload).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.syncDemoUserSuccess(epicRes.data.Payload)
          } else {
            return intouchAction.syncDemoUserFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.syncDemoUserFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'DELETE_DEMO_USER' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.demoUserDelete.get({ clientid: a.payload.clientid }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.deleteDemoUserSuccess(a.payload)
          } else {
            return intouchAction.deleteDemoUserFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.deleteDemoUserFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_API_CUSTOMERS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.apiLogin.get().pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.requestApiCustomersSuccess(epicRes.data.Payload)
          } else {
            return intouchAction.requestApiCustomersFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.requestApiCustomersFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'ADD_API_CUSTOMER' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.newApiLogin.post(a.payload).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.addApiCustomerSuccess(epicRes.data.Payload)
          } else {
            return intouchAction.addApiCustomerFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.addApiCustomerFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'UPDATE_API_CUSTOMER' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.updateApiLogin.put(a.payload).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.updateApiCustomerSuccess(epicRes.data.Payload)
          } else {
            return intouchAction.updateApiCustomerFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.updateApiCustomerFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'UPDATE_API_CUSTOMER_ENABLED' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.updateApiLoginEnabled.put(a.payload).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.updateApiCustomerEnabledSuccess(a.payload)
          } else {
            return intouchAction.updateApiCustomerEnabledFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.updateApiCustomerEnabledFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'ADD_API_CLIENT' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.apiLoginClient.post(a.payload).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.addApiClientSuccess({ res: epicRes.data.Payload, req: a.payload })
          } else {
            return intouchAction.addApiClientFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.addApiClientFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'DELETE_API_CLIENT' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.deleteApiLoginClient.delete({ id: a.payload.id.toString() }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.deleteApiClientSuccess(a.payload)
          } else {
            return intouchAction.deleteApiClientFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.deleteApiClientFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_FEED_LIBRARY' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.feedLibrary.get({ clientId: a.payload.clientId.toString() }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            if (epicRes.data.Payload?.RegionId)
              return intouchAction.requestFeedLibraryFailed('This account already has a Feed Library associated with it')
            return intouchAction.requestFeedLibrarySuccess(epicRes.data.Payload)
          } else {
            return intouchAction.requestFeedLibraryFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.requestFeedLibraryFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'UPDATE_FEED_LIBRARY' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.feedLibraries.put().pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.updateFeedLibrarySuccess(epicRes.data.Payload)
          } else {
            return intouchAction.updateFeedLibraryFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.updateFeedLibraryFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'SUBMIT_FEED_LIBRARY' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.submitFeedLibrary.post(a.payload).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.submitFeedLibrarySuccess(epicRes.data.Payload)
          } else {
            return intouchAction.submitFeedLibraryFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.submitFeedLibraryFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_REGIONS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.regions.get().pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.requestRegionsSuccess(epicRes.data.Payload)
          } else {
            return intouchAction.requestRegionsFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.requestRegionsFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_PROVIDERS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.providers.get().pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.requestProvidersSuccess(epicRes.data.Payload)
          } else {
            return intouchAction.requestProvidersFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.requestProvidersFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_PROVIDER_MAPPINGS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.providerMappings.get().pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.requestProviderMappingsSuccess(epicRes.data.Payload)
          } else {
            return intouchAction.requestProviderMappingsFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.requestProviderMappingsFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'ADD_PROVIDER_MAPPING' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.updateProviderMap.post(a.payload).pipe(
        mergeMap(epicRes => {
          if (epicRes.status === 200 || epicRes.status === 202) {
              const successAction = intouchAction.addProviderMappingSuccess(epicRes.data.Payload)

              const fetchAction = intouchAction.requestProviderMappings()
              return [successAction, fetchAction]
          } else {
              return [intouchAction.addProviderMappingFailed(epicRes.data?.error as string || '')]
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.addProviderMappingFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'UPDATE_PROVIDER_MAPPING' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.updateProviderMap.put(a.payload).pipe(
        mergeMap(epicRes => {
          if (epicRes.status === 200) {
            const successAction = intouchAction.updateProviderMappingSuccess(epicRes.data.Payload)

            const fetchAction = intouchAction.requestProviderMappings()
            return [successAction, fetchAction]
          } else {
            return [intouchAction.updateProviderMappingFailed(epicRes.data?.error as string || '')]
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.updateProviderMappingFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'DELETE_PROVIDER_MAPPING' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.deleteProviderMap.delete({ mapId: a.payload.mapId }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.deleteProviderMappingSuccess(a.payload.mapId)
          } else {
            return intouchAction.deleteProviderMappingFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.deleteProviderMappingFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_AUTO_RPMS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.autoRpmList.get({ id: a.payload.id }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.requestAutoRpmsSuccess(epicRes.data.Payload)
          } else {
            return intouchAction.requestAutoRpmsFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.requestAutoRpmsFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'ADD_AUTO_RPM' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.addAutoRpm.post(a.payload).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.addAutoRpmSuccess(epicRes.data.Payload)
          } else {
            return intouchAction.addAutoRpmFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.addAutoRpmFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'UPDATE_AUTO_RPM' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.updateAutoRpm.put(a.payload).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.updateAutoRpmSuccess(a.payload)
          } else {
            return intouchAction.updateAutoRpmFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.updateAutoRpmFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'DELETE_AUTO_RPM' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.deleteAutoRpm.delete({ rpmId: a.payload.rpmId }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.deleteAutoRpmSuccess({ rpmId: a.payload.rpmId })
          } else {
            return intouchAction.deleteAutoRpmFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.deleteAutoRpmFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_AUTO_RPM_CONFIGS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.autoRpmConfigs.get({ rpmId: a.payload.rpmId }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.requestAutoRpmConfigsSuccess(epicRes.data.Payload)
          } else {
            return intouchAction.requestAutoRpmConfigsFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.requestAutoRpmConfigsFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'ADD_EXTERNAL_AUTO_RPM_CONFIG' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.addExternalAutoRpmConfig.post(a.payload).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.addExternalAutoRpmConfigSuccess(epicRes.data.Payload)
          } else {
            return intouchAction.addExternalAutoRpmConfigFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.addExternalAutoRpmConfigFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'ADD_INTERNAL_AUTO_RPM_CONFIG' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.addInternalAutoRpmConfig.post(a.payload).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.addInternalAutoRpmConfigSuccess(epicRes.data.Payload)
          } else {
            return intouchAction.addInternalAutoRpmConfigFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.addInternalAutoRpmConfigFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'DELETE_AUTO_RPM_CONFIG' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.deleteAutoRpmConfig.delete({ id: a.payload.id.toString() }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.deleteAutoRpmConfigSuccess(a.payload.id)
          } else {
            return intouchAction.deleteAutoRpmConfigFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.deleteAutoRpmConfigFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_AUTO_RPM_SCHEDULER' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.autoRpmScheduler.get({ id: a.payload.id }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.requestAutoRpmSchedulerSuccess(epicRes.data.Payload)
          } else {
            return intouchAction.requestAutoRpmSchedulerFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.requestAutoRpmSchedulerFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'SUBMIT_AUTO_RPM_SCHEDULER' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.submitAutoRpm.post(a.payload).pipe(
        map(epicRes => {
          if (epicRes.status === 200 || epicRes.status === 202) {
            return intouchAction.submitAutoRpmSchedulerSuccess(epicRes.data.Payload)
          } else {
            return [intouchAction.submitAutoRpmSchedulerFailed(epicRes.data?.error as string || '')]
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.submitAutoRpmSchedulerFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_DDW_SCHEDULER' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.ddwSchedule.get({ clientId: a.payload.clientId }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.requestDDWSchedulerSuccess(epicRes.data.Payload)
          } else {
            return intouchAction.requestDDWSchedulerFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.requestDDWSchedulerFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'SUBMIT_DDW_SCHEDULER' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.createDDWSchedule.post(a.payload).pipe(
        map(epicRes => {
          if (epicRes.status === 200 || epicRes.status === 202) {
            return intouchAction.submitDDWSchedulerSuccess(epicRes.data.Payload)
          } else {
            return [intouchAction.submitDDWSchedulerFailed(epicRes.data?.error as string || '')]
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.submitDDWSchedulerFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_FEED_PENS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.feedPens.get({ clientId: a.payload.clientId }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.requestFeedPensSuccess(epicRes.data.Payload)
          } else {
            return intouchAction.requestFeedPensFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.requestFeedPensFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_MILK_PENS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.milkPens.get({ clientId: a.payload.clientId }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.requestMilkPensSuccess(epicRes.data.Payload)
          } else {
            return intouchAction.requestMilkPensFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.requestMilkPensFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_ACCURACY_REPORTS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.reports.get().pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.requestAccuracyReportsSuccess(epicRes.data.Payload)
          } else {
            return intouchAction.requestAccuracyReportsFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.requestAccuracyReportsFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_REPORT_CONTRACT_INCLUSIONS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.reportInclusionList.get({ id: a.payload.id }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.requestReportContractInclusionsSuccess(epicRes.data.Payload)
          } else {
            return intouchAction.requestReportContractInclusionsFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.requestReportContractInclusionsFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_MARKETS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.markets.get().pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.requestMarketsSuccess(epicRes.data.Payload)
          } else {
            return intouchAction.requestMarketsFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.requestMarketsFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_INTOUCH_CONTRACT_MARKETS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.intouchContractMarket.get().pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.requestIntouchContractMarketsSuccess(epicRes.data.Payload)
          } else {
            return intouchAction.requestIntouchContractMarketsFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.requestIntouchContractMarketsFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'ADD_REPORT_INCLUSION' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.addReportInclusion.post(a.payload).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.addReportInclusionSuccess(epicRes.data.Payload)
          } else {
            return intouchAction.addReportInclusionFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.addReportInclusionFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'DELETE_REPORT_INCLUSION' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.deleteReportInclusion.delete({ id: a.payload.id.toString() }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.deleteReportInclusionSuccess(a.payload.id)
          } else {
            return intouchAction.deleteReportInclusionFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.deleteReportInclusionFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_REPORT_CONFIG_EXCLUSIONS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.reportExclusionList.get({ id: a.payload.id.toString() }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.requestReportConfigExclusionsSuccess(epicRes.data.Payload)
          } else {
            return intouchAction.requestReportConfigExclusionsFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.requestReportConfigExclusionsFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'ADD_REPORT_EXCLUSION' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.addReportExclusion.post(a.payload).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.addReportExclusionSuccess(epicRes.data.Payload)
          } else {
            return intouchAction.addReportExclusionFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.addReportExclusionFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'UPDATE_REPORT_EXCLUSION' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.updateReportExclusion.put(a.payload).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.updateReportExclusionSuccess(a.payload)
          } else {
            return intouchAction.updateReportExclusionFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.updateReportExclusionFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'DELETE_REPORT_EXCLUSION' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.deleteReportExclusion.delete({ id: a.payload.id.toString() }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.deleteReportExclusionSuccess(a.payload.id)
          } else {
            return intouchAction.deleteReportExclusionFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.deleteReportExclusionFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_ACCURACY_CLIENTS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.accuracyClients.get().pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.requestAccuracyClientsSuccess(epicRes.data.Payload)
          } else {
            return intouchAction.requestAccuracyClientsFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.requestAccuracyClientsFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_REPORT_CONFIG_INCLUSIONS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.reportConfigInclusionList.get({ id: a.payload.id.toString() }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.requestReportConfigInclusionsSuccess(epicRes.data.Payload)
          } else {
            return intouchAction.requestReportConfigInclusionsFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.requestReportConfigInclusionsFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'ADD_REPORT_CONFIG_INCLUSION' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.addReportConfigInclusion.post(a.payload).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.addReportConfigInclusionSuccess(epicRes.data.Payload)
          } else {
            return intouchAction.addReportConfigInclusionFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.addReportConfigInclusionFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'UPDATE_REPORT_CONFIG_INCLUSION' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.updateReportConfigInclusion.put(a.payload).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.updateReportConfigInclusionSuccess(a.payload)
          } else {
            return intouchAction.updateReportConfigInclusionFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.updateReportConfigInclusionFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'DELETE_REPORT_CONFIG_INCLUSION' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.deleteReportConfigInclusion.delete({ id: a.payload.id.toString() }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.deleteReportConfigInclusionSuccess({ id: a.payload.id })
          } else {
            return intouchAction.deleteReportConfigInclusionFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.deleteReportConfigInclusionFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_MAIL_REGIONS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.mailRegions.get().pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return intouchAction.requestMailRegionsSuccess(epicRes.data.Payload)
          } else {
            return intouchAction.requestMailRegionsFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(intouchAction.requestMailRegionsFailed(epicError))
        })
      )
    })
  ),

)