import { Array as RtArray, Boolean as RtBoolean, Null, Number, Record as RtRecord, String, Undefined } from 'runtypes';
import { AddReportExclusionRecord, AutoRpmListRecord, AutoRpmRecord, ProviderMappingsRecord, ReportExclusionRecord, ReportInclusionRecord, ReportInclusionRequest, DDWSchedulerRequest, DDWSchedulerResponse, RpmRecord, AutoRpmSchedulerRequest, AutoRpmSchedulerResponse, TargetCategoriesRecord, TimezoneRecord, UpdateProviderMappingRecord } from './API-records';
import { DateString } from './custom.runtypes';
import { EndpointDefintion } from "./endpoint.class";

export const endpoints = {

  // #region Users
  userFeatures: new EndpointDefintion(
    `${process.env.REACT_APP_API_URL}/menufunction`,
    RtArray(RtRecord({
      WebFunction: Number,
      HasWriteAccess: RtBoolean,
      UserName: String
    })),
    undefined,
    {
      queryParams: {
        login: (email: string) => email
      }
    }
  ),

  accounts: new EndpointDefintion(
    (routeParams: { search: string }) => `/values/clientcodesforintouch/${routeParams.search}`,
    RtArray(RtRecord({
      ClientId: String,
      ClientCode: String,
      ClientName: String
    })),
    undefined
  ),

  nutritionists: new EndpointDefintion(
    (routeParams: { search: string }) => `/values/nutritionists/${routeParams.search}`,
    RtArray(RtRecord({
      USERID: String,
      USERNAME: String,
      FIRSTNAME: String,
      LASTNAME: String
    })),
    undefined
  ),
  
  //#endregion

  // #region Reports

  reports: new EndpointDefintion(
    `/values/reports`,
    RtArray(RtRecord({
      ReportId: Number,
      ReportName: String
    })),
    undefined
  ),

  countries: new EndpointDefintion(
    `/values/countries`,
    RtArray(RtRecord({
      CountryName: String,
      LanguageCode: String.Or(Null)
    })),
    undefined
  ),

  translations: new EndpointDefintion(
    (routeParams: { report: string, languageCode: string }) => `/reports/translations/${routeParams.report}/${routeParams.languageCode}`,
    RtArray(RtRecord({
      FieldName: String,
      FieldText: String
    })),
    undefined
  ),

  translationElements: new EndpointDefintion(
    (routeParams: { report: string }) => `/reports/translationelements/${routeParams.report}`,
    RtArray(RtRecord({
      FieldName: String
    })),
    undefined
  ),

  submitTranslations: new EndpointDefintion(
    (routeParams: { report: string, languageCode: string }) => `/reports/translations/${routeParams.report}/${routeParams.languageCode}`,
    RtBoolean,
    RtArray(RtRecord({
      FieldName: String,
      FieldText: String,
      LanguageCode: String,
      ReportName: String
    })),
  ),

  allProducts: new EndpointDefintion( // all products for product maintaintance screen
    `/values/alltechproductgroupsall`,
    RtArray(RtRecord({
      AlltechProductGroupId: Number,
      AlltechProductGroupName: String,
      AlltechProductGroupCode: String,
      IsSelectable: RtBoolean
    })),
    undefined
  ),

  productsList: new EndpointDefintion( // for main screen dropdown
    `/values/alltechproductgroupsDropDown`,
    RtArray(RtRecord({
      AlltechProductGroupId: Number,
      AlltechProductGroupName: String,
      AlltechProductGroupCode: String,
      IsSelectable: RtBoolean
    })),
    undefined
  ),

  updateAlltechProduct: new EndpointDefintion( // update selectable value only as other values are readonly
    `/values/alltechproductgroup/`,
    RtBoolean,
    RtRecord({
      AlltechProductGroupId: Number,
      IsSelectable: RtBoolean
    })
  ),

  productsUom: new EndpointDefintion(
    `/values/alltechproductUoM`,
    RtArray(RtRecord({
      AlltechProductGroupUoMId: Number,
      UoMName: String,
      KgMultiplier: Number
    })),
    undefined
  ),

  allProductUsage: new EndpointDefintion(
    `/reports/ProductUsage/All`,
    RtArray(RtRecord({
      AlltechProductGroupUsageId: Number,
      Username: String,
      AlltechProductGroupId: Number,
      StartDate: DateString,
      EndDate: DateString.Or(Undefined).Or(Null),
      Quantity: Number,
      AlltechProductUoMId: Number
    })),
    undefined
  ),

  upsertProductUsage: new EndpointDefintion(
    `/reports/ProductUsage/upsert`,
    RtBoolean,
    RtRecord({
      AlltechProductGroupUsageId: Number,
      Username: String,
      AlltechProductGroupId: Number,
      StartDate: DateString,
      EndDate: DateString.Or(Undefined).Or(Null),
      Quantity: Number,
      AlltechProductUoMId: Number
    })
  ),

  deleteProductUsage: new EndpointDefintion(
    `reports/productusage`,
    RtBoolean,
    undefined,
    {
      queryParams: {
        id: (id: string) => id
      }
    }
  ),

  //#endregion
  
  // #region RPM

  clientRpms: new EndpointDefintion(
    (routeParams: { clientId: string }) => `/intouch/rpm/${routeParams.clientId}`,
    RtArray(RpmRecord),
    undefined
  ),

  nutritionistRpms: new EndpointDefintion(
    (routeParams: { id: string }) => `/InTouch/RPMNutritionist/${routeParams.id}`,
    RtArray(RpmRecord),
    undefined
  ),

  updateComment: new EndpointDefintion(
    `/intouch/rpm/`,
    RtBoolean,
    RtRecord({
      RpmId: String,
      ValidatedComment: String.Or(Null).Or(Undefined)
    })
  ),

  updateValidation: new EndpointDefintion(
    `/intouch/rpm/`,
    RtBoolean,
    RtRecord({
      RpmId: String,
      IsValidated: String
    })
  ),

  rpmEmailList: new EndpointDefintion(
    (routeParams: { clientCode: string }) => `/values/rpmemails/${routeParams.clientCode}`,
    RtArray(RtRecord({
        email: String,
        username: String,
        id: Number
    })),
    undefined,
  ),
  
  sendRpmEmail: new EndpointDefintion(
    `/Misc/EmailExecutionRpm`,
    RtBoolean,
    RtRecord({
        RpmId: Number,
        Email: RtArray(String),
        RpmType: String
    }),
  ),

  //#endregion

  // #region Admin

  methodGroupRole: new EndpointDefintion(
    `/SysAdmin/MethodGroupRole`,
    RtArray(RtRecord({
      MethodGroupRoleId: Number,
      MethodGroupName: String,
      RoleId: Number
    })),
    undefined
  ),

  methodGroups: new EndpointDefintion(
    `/values/methodgroups`,
    RtRecord({}),
    undefined
  ),

  roles: new EndpointDefintion(
    `/values/roles`,
    RtArray(RtRecord({
      RoleId: Number,
      RoleName: String
    })),
    undefined
  ),

  role: new EndpointDefintion(
    `/SysAdmin/role`,
    RtRecord({
      RoleId: Number,
      RoleName: String
    }),
    RtRecord({
      RoleName: String
    })
  ),

  users: new EndpointDefintion(
    `/SysAdmin/user/0`,
    RtArray(RtRecord({
      InternalUserId: Number,
      Login: String
    })),
    undefined
  ),

  newUser: new EndpointDefintion(
    `/SysAdmin/user`,
    RtRecord({
      InternalUserId: Number,
      Login: String
    }),
    RtRecord({
      InternalUserId: Number,
      InternalUserName: String
    })
  ),

  deleteUser: new EndpointDefintion(
    (routeParams: { userId: string }) => `/SysAdmin/user/${routeParams.userId}`,
    RtBoolean,
    undefined
  ),

  addMethodGroupRole: new EndpointDefintion(
    `/SysAdmin/MethodGroupRole`,
    RtRecord({
      MethodGroupRoleId: Number,
      MethodGroupName: String,
      RoleId: Number
    }),
    RtRecord({
      MethodGroupRoleId: Number,
      MethodGroupName: String,
      RoleId: Number
    })
  ),

  deleteMethodGroupRole: new EndpointDefintion(
    (routeParams: { id: string }) => `/SysAdmin/MethodGroupRole/${routeParams.id}`,
    RtBoolean,
    undefined
  ),

  roleUsers: new EndpointDefintion(
    (routeParams: { id: string }) => `/SysAdmin/userrole/role/${routeParams.id}`,
    RtArray(RtRecord({
      InternalUser: RtRecord({
          InternalUserId: Number,
          Login: String
      }),
      UserRoleId: Number,
      InternalUserId: Number,
      RoleId: Number,
      HasWriteAccess: RtBoolean
    })),
    undefined
  ),

  deleteRoleUser: new EndpointDefintion(
    (routeParams: { userId: string }) => `/SysAdmin/userrole/${routeParams.userId}`,
    RtBoolean,
    undefined
  ),
  
  userRoles: new EndpointDefintion(
    (routeParams: { id: string }) => `/SysAdmin/userrole/user/${routeParams.id}`,
    RtArray(RtRecord({
      InternalUserId: Number,
      login: String,
      UserRoleId: Number,
      RoleId: Number,
      HasWriteAccess: RtBoolean,
      RoleName: String
    })),
    undefined
  ),

  addRoleUser: new EndpointDefintion(
    `/SysAdmin/userrole/`,
    RtRecord({
      HasWriteAccess: RtBoolean.Or(Undefined).Or(Null),
      InternalUser: String.Or(Undefined).Or(Null),
      InternalUserId: Number.Or(Undefined).Or(Null),
      RoleId: Number.Or(Undefined).Or(Null),
      UserRoleId: Number.Or(Undefined).Or(Null)
    }),
    RtRecord({
      InternalUserId: Number.Or(Undefined).Or(Null),
      RoleId: Number.Or(Undefined).Or(Null),
      HasWriteAccess: RtBoolean.Or(Undefined).Or(Null),
    })
  ),

  updateRoleUser: new EndpointDefintion(
    `/SysAdmin/userrole`,
    RtBoolean,
    RtRecord({
      UserRoleId: Number.Or(Undefined).Or(Null),
      InternalUserId: Number.Or(Undefined).Or(Null),
      RoleId: Number.Or(Undefined).Or(Null),
      HasWriteAccess: RtBoolean.Or(Undefined).Or(Null),
    })
  ),

  //#endregion

  // #region Sales
  
  order: new EndpointDefintion(
    (routeParams: { id: string }) => `/sales/order/${routeParams.id}`,
    RtRecord({
      SALESORDERID: String.Or(Null).Or(Undefined),
      CUSTOMERPURCHASEORDERNUMBER: String.Or(Null).Or(Undefined),
      MINPRICEUNDERAUTHORISED: String.Or(Null).Or(Undefined),
    }),
    undefined
  ),

  authoriseOrder: new EndpointDefintion(
    `/Sales/OrderAuth`,
    RtRecord({
      SALESORDERID: String.Or(Null).Or(Undefined),
      CUSTOMERPURCHASEORDERNUMBER: String.Or(Null).Or(Undefined),
      MINPRICEUNDERAUTHORISED: String.Or(Null).Or(Undefined),
    }),
    RtRecord({
      SalesOrderId: String
    })
  ),

  tradeIns: new EndpointDefintion(
    (routeParams: { orderId: string }) => `/Sales/Tradeins/${routeParams.orderId}`,
    RtArray(RtRecord({
      C_ACCPRODTRADEINID: Number,
      TRADEINPRICE: Number,
      MAXTRADINPRICE: Number,
      C_PURCHASEDATE: DateString,
      NAME: String
    })),
    undefined
  ),

  saveTradeIns: new EndpointDefintion(
    `/Sales/Tradeins`,
    RtBoolean,
    RtArray(RtRecord({
      TradeInPriceId: Number,
      MaxTradeInPrice: Number
    }))
  ),

  salesStaffs: new EndpointDefintion(
    `/values/salesstaff`,
    RtArray(RtRecord({
      USERID: String,
      USERNAME: String,
      DEPARTMENT: String,
      DIVISION: String,
      REGION: String
    })),
    undefined
  ),
  
  targetCategories: new EndpointDefintion(
    `/values/targetcategories`,
    RtArray(TargetCategoriesRecord),
    undefined
  ),

  salesTargets: new EndpointDefintion(
    `/Sales/Targets`,
    RtArray(RtRecord({
      SalesTargetId: Number,
      TargetValue: Number,
      TargetCategoryId: Number,
      UserId: String,
      Market: String,
      TargetYear: Number,
      TargetMonth: Number
    })),
    undefined,
    {
      queryParams: {
        targetCategoryId: (t: number) => t,
        market: (m: string) => m,
        year: (y: number) => y
      }
    }
  ),

  updateSalesTarget: new EndpointDefintion(
    `/Sales/Targets`,
    RtRecord({
      SalesTargetId: Number,
      TargetValue: Number,
      TargetCategoryId: Number,
      UserId: String,
      Market: String,
      TargetYear: Number,
      TargetMonth: Number
    }),
    RtRecord({
      CategorisedTargetId: Number.Or(Null).Or(Undefined),
      UserId: String.Or(Null).Or(Undefined),
      Market: String,
      CategoryId: Number,
      TargetValue: Number,
      Month: Number,
      Year: Number
    })
  ),

  //#endregion

  // #region Intouch

  uom: new EndpointDefintion(
    `/values/uom`,
    RtArray(RtRecord({
      UnitOfMeasureId: Number,
      UnitOfMeasureCode: String,
      UnitOfMeasureDescription: String
    })),
    undefined
  ),

  uomMaps: new EndpointDefintion(
    `/InTouch/UnitOfMeasureMappings`,
    RtArray(RtRecord({
      UnitOfMeasureClientMappingId: Number,
      UnitOfMeasureId: Number,
      ClientId: String
    })),
    undefined
  ),

  updateUomMapping: new EndpointDefintion(
    `/InTouch/UnitOfMeasureMappings`,
    RtRecord({
      UnitOfMeasureClientMappingId: Number,
      UnitOfMeasureId: Number,
      ClientId: String
    }),
    RtRecord({
      UnitOfMeasureId: Number,
      ClientId: String
    })
  ),

  deleteUomMapping: new EndpointDefintion(
    (routeParams: { mapId: string }) => `/InTouch/UnitOfMeasureMappings/${routeParams.mapId}`,
    RtBoolean,
    undefined
  ),

  demoAccounts: new EndpointDefintion(
    `/Intouch/DemoAccounts`,
    RtArray(RtRecord({
      DemoClientCode: String,
      DemoClientId: Number,
      LastRefreshed: DateString,
      OriginalClientCode: String,
    })),
    undefined
  ),

  demoUser: new EndpointDefintion(
    `/Intouch/DemoAccount`,
    RtBoolean,
    RtRecord({
      ClientId: String,
      DemoClientId: String
    })
  ),
  
  demoUserDelete: new EndpointDefintion(
    `/Intouch/DemoAccountDelete`,
    RtBoolean,
    undefined,
    {
      queryParams: {
        clientid: (id: string) => id
      }
    }
  ),

  apiLogin: new EndpointDefintion(
    `/InTouch/ApiLogin`,
    RtArray(RtRecord({
      LoginId: Number,
      Login: String,
      IsEnabled: RtBoolean,
      Clients: RtArray(RtRecord({
        Id: Number,
        LoginId: Number,
        Client: String
      }))
    })),
    undefined
  ),

  newApiLogin: new EndpointDefintion(
    `/InTouch/ApiLogin`,
    RtRecord({
      DatapigApiLoginId: Number,
      UserName: String,
      IsEnabled: RtBoolean,
      Client: RtArray(RtRecord({
        Id: Number,
        LoginId: Number,
        Client: String
      }))
    }),
    RtRecord({
      Login: String,
      Password: String
    })
  ),

  updateApiLogin: new EndpointDefintion(
    `/InTouch/ApiLogin`,
    RtBoolean,
    RtRecord({
      Login: String,
      LoginId: Number,
      IsEnabled: RtBoolean,
      Password: String
    }),
  ),

  updateApiLoginEnabled: new EndpointDefintion(
    `/InTouch/ApiLogin`,
    RtBoolean,
    RtRecord({
      Login: String,
      LoginId: Number,
      IsEnabled: RtBoolean,
    }),
  ),

  apiLoginClient: new EndpointDefintion(
    `/InTouch/ApiLoginClient`,
    RtRecord({
      DatapigApiLoginClientId: Number,
      DatapigApiLoginId: Number,
      Client: String
    }),
    RtRecord({
      LoginId: Number,
      Client: String
    })
  ),

  deleteApiLoginClient: new EndpointDefintion(
    `/InTouch/ApiLoginClient`,
    RtBoolean,
    RtRecord({
      LoginId: Number
    }),
    {
      queryParams: {
        id: (id: string) => id
      }
    }
  ),

  feedLibrary: new EndpointDefintion(
    (routeParams: { clientId: string }) => `/InTouch/FeedLibrary/${routeParams.clientId}`,
    RtRecord({
      ClientId: String,
      RegionId: Number
    }),
    undefined
  ),
  
  feedLibraries: new EndpointDefintion(
    `/InTouch/FeedLibrary`,
    RtBoolean,
    undefined
  ),

  submitFeedLibrary: new EndpointDefintion(
    `/InTouch/FeedLibrary`,
    RtBoolean,
    RtRecord({
      ClientId: String,
      RegionId: Number
    })
  ),

  regions: new EndpointDefintion(
    `/values/regions`,
    RtArray(RtRecord({
      RegionId: Number,
      RegionName: String
    })),
    undefined
  ),

  providers: new EndpointDefintion(
    `/values/providers`,
    RtArray(RtRecord({
      MilkDataProviderId: Number,
      ProviderCode: String,
      ProviderName: String
    })),
    undefined
  ),

  providerMappings: new EndpointDefintion(
    `intouch/ProviderMappingsAll`,
    RtArray(ProviderMappingsRecord),
    undefined
  ),
  
  updateProviderMap: new EndpointDefintion(
    `/InTouch/ProviderMappings`,
    UpdateProviderMappingRecord,
    RtRecord({
      ExternalClientId: String,
      Provider: String,
      Username: String,
      ProviderClientMappingId: Number,
      HasMapping: RtBoolean
    })
  ),
  
  deleteProviderMap: new EndpointDefintion(
    (routeParams: { mapId: string }) => `/InTouch/ProviderMappings/${routeParams.mapId}`,
    RtBoolean,
    undefined
  ),

  autoRpmList: new EndpointDefintion(
    (routeParams: { id: string }) => `/InTouch/AutoRpm/${routeParams.id}`,
    RtArray(AutoRpmListRecord),
    undefined
  ),

  addAutoRpm: new EndpointDefintion(
    `/InTouch/AutoRpm/`,
    AutoRpmListRecord,
    RtRecord({
      RpmTitle: String,
      ProviderClientMappingId: Number,
      Enabled: RtBoolean
    })
  ),
  
  updateAutoRpm: new EndpointDefintion(
    `/InTouch/AutoRpm/`,
    RtBoolean,
    RtRecord({
      AutoRpmId: Number,
      RpmTitle: String,
      ProviderClientMappingId: Number,
      Enabled: RtBoolean
    })
  ),

  deleteAutoRpm: new EndpointDefintion(
    (routeParams: { rpmId: string }) => `/InTouch/AutoRpm/${routeParams.rpmId}`,
    RtRecord({
      AutoRpmConfigId: Number,
      AutoRpmId: Number,
      IsAlltechGroup: RtBoolean,
      InternalPenId: Number,
      InternalPenName: String,
      ExternalPenReference: String
    }),
    undefined
  ),

  autoRpmConfigs: new EndpointDefintion(
    (routeParams: { rpmId: string }) => `/InTouch/AutoRpmConfig/${routeParams.rpmId}`,
    RtArray(AutoRpmRecord),
    undefined
  ),

  addExternalAutoRpmConfig: new EndpointDefintion(
    `/InTouch/AutoRpmConfig`,
    AutoRpmRecord,
    RtRecord({
      AutoRpmId: Number,
      ExternalPenReference: String.Or(Null).Or(Undefined)
    })
  ),

  addInternalAutoRpmConfig: new EndpointDefintion(
    `/InTouch/AutoRpmConfig`,
    AutoRpmRecord,
    RtRecord({
      AutoRpmId: Number,
      IsAlltechGroup: RtBoolean,
      InternalPenId: Number.Or(Null).Or(Undefined),
      InternalPenName: String.Or(Null).Or(Undefined)
    })
  ),

  deleteAutoRpmConfig: new EndpointDefintion(
    (routeParams: { id: string }) => `/InTouch/AutoRpmConfig/${routeParams.id}`,
    RtBoolean,
    undefined
  ),

  autoRpmScheduler: new EndpointDefintion(
    (routeParams: { id: string }) => `/InTouch/AutoRpmConfig/schedule/${routeParams.id}`,
    RtArray(AutoRpmSchedulerResponse),
    undefined
  ),

  submitAutoRpm: new EndpointDefintion(
    `/InTouch/AutoRpmConfig/schedule`,
    RtBoolean,
    AutoRpmSchedulerRequest
  ),
  
  feedPens: new EndpointDefintion(
    (routeParams: { clientId: string }) => `/InTouch/IntouchFeedPens/${routeParams.clientId}`,
    RtArray(RtRecord({
      PenDescription: String,
      PenId: Number
    })),
    undefined
  ),

  milkPens: new EndpointDefintion(
    (routeParams: { clientId: string }) => `/InTouch/IntouchMilkPens/${routeParams.clientId}`,
    RtArray(RtRecord({
      PenDescription: String,
    })),
    undefined
  ),

  reportInclusionList: new EndpointDefintion(
    (routeParams: { id: string }) => `/InTouch/ReportContractInclusions/${routeParams.id}`,
    RtArray(RtRecord({
      ReportContractInclusionId: Number,
      InTouchProductSlxId: String,
      Market: String,
      ReportId: Number
    })),
    undefined
  ),

  markets: new EndpointDefintion(
    `/values/markets`,
    RtArray(RtRecord({
      Key: String,
      Value: String
    })),
    undefined
  ),

  intouchContractMarket: new EndpointDefintion(
    `/values/intouchcontractmarkets`,
    RtArray(RtRecord({
      InTouchProductId: Number,
      InTouchProductName: String,
      InTouchProductDescription: String,
      InTouchProductSlxId: String,
      market: String
    })),
    undefined
  ),

  addReportInclusion: new EndpointDefintion(
    `/InTouch/ReportContractInclusion`,
    RtRecord({
      ReportContractInclusionId: Number,
      InTouchProductSlxId: String,
      Market: String,
      ReportId: Number
    }),
    RtRecord({
      ReportId: Number,
      InTouchProductSlxId: String,
      Market: String
    })
  ),

  deleteReportInclusion: new EndpointDefintion(
    (routeParams: { id: string }) => `/InTouch/ReportContractInclusion/${routeParams.id}`,
    RtBoolean,
    undefined
  ),

  reportExclusionList: new EndpointDefintion(
    (routeParams: { id: string }) => `/InTouch/reportExclusions/${routeParams.id}`,
    RtArray(ReportExclusionRecord),
    undefined
  ),
  
  addReportExclusion: new EndpointDefintion(
    `/InTouch/reportExclusion`,
   ReportExclusionRecord,
   AddReportExclusionRecord
  ),

 updateReportExclusion: new EndpointDefintion(
    `/InTouch/reportExclusion`,
   RtBoolean,
   ReportExclusionRecord
  ),
  
  deleteReportExclusion: new EndpointDefintion(
    (routeParams: { id: string }) => `/InTouch/reportExclusion/${routeParams.id}`,
    RtBoolean,
    undefined
  ),

  accuracyClients: new EndpointDefintion(
    `/InTouch/AccuracyClients`,
    RtArray(RtRecord({
      AccuracyReportQualificationId: Number,
      PrimaryContactEmail: String,
      Client: String,
      AccountEmail: String,
      ThirdPartyEmail: String,
      NutritionistEmail: String
    })),
    undefined
  ),

  reportConfigInclusionList: new EndpointDefintion(
    (routeParams: { id: string }) => `/InTouch/reportInclusions/${routeParams.id}`,
    RtArray(ReportInclusionRecord),
    undefined
  ),
  
  addReportConfigInclusion: new EndpointDefintion(
    `/InTouch/reportinclusion`,
    ReportInclusionRecord,
    ReportInclusionRequest
  ),

  updateReportConfigInclusion: new EndpointDefintion(
    `/InTouch/reportinclusion`,
    RtBoolean,
    ReportInclusionRequest
  ),

  deleteReportConfigInclusion: new EndpointDefintion(
    (routeParams: { id: string }) => `/InTouch/reportinclusion/${routeParams.id}`,
    RtBoolean,
    undefined
  ),

  mailRegions: new EndpointDefintion(
    `/values/mailregions`,
    RtArray(RtRecord({
      MailRegionId: Number,
      MailRegionName: String
    })),
    undefined
  ),

  ddwSchedule: new EndpointDefintion(
    (routeParams: { clientId: string }) => `/InTouch/ProviderMappings/schedule/${routeParams.clientId}`,
    DDWSchedulerResponse,
    undefined
  ),
  
  createDDWSchedule: new EndpointDefintion(
    `/InTouch/ProviderMappings/schedule`,
    RtBoolean,
    DDWSchedulerRequest
  ),

  //#endregion

  clientProductUsage: new EndpointDefintion(
    `/reports/ProductUsage/client`,
    RtArray(RtRecord({
      AlltechProductGroupUsageId: Number,
      Username: String,
      AlltechProductGroupId: Number,
      StartDate: DateString,
      EndDate: DateString.Or(Undefined).Or(Null),
      Quantity: Number,
      AlltechProductUoMId: Number
    })),
    undefined,
    {
      queryParams: {
        username: (u: string) => u
      }
    }
  ),
}

/* OLD OR NO LONGER USED ENDPOINTS */

// export const AUTOMILK = `/Dynamicrpm/AutoMilk`
// export const CONTRACTMAPPINGS = `/OnlinePayment/ContractMapping`
// export const FEATURES = `/api/function`
// export const INTOUCHCONTRACTS = `/values/intouchcontracts`
// export const INTOUCHPRODUCTS = `/values/intouchproducts`
// export const LEAGUETABLE = `/InTouch/LeagueTable`
// export const LOGIN = `/api/security`
// export const MANUALMILK = `/Dynamicrpm/ManualMilk`
// export const PROVIDERPENS = `/values/providerpens`
// export const RPMEDIT = `/Dynamicrpm/Production`
