import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { AppBarLoadingContext } from 'src/contexts/appbar-loading.context'
import { EnabledSecurityContext } from 'src/contexts/enable-security.context'
import { sortSubFeatures } from 'src/lib/utils/methods'
import { useTypedAppSelector } from 'src/store'
import { intouchAction } from 'src/store/actions/intouch.actions'
import { GridActionsCellItem, GridRenderCellParams, GridRowParams, useGridApiRef } from '@mui/x-data-grid-pro'
import { BinIcon } from 'src/components/icons/bin'
import { Alert, Button, Dialog, DialogContent, IconButton, Paper, Snackbar, TextField, Tooltip, Typography } from '@mui/material'
import { DataGridComponent } from 'src/components/data-grid/data-grid'
import { SnackBarContext } from 'src/contexts/snack-bar.context'
import { intouchSelectors } from 'src/store/selectors/intouch.selector'
import { SettingsIcon } from 'src/components/icons/settings'
import { useHistory, useParams } from 'react-router'
import { ArrowLeftIcon } from 'src/components/icons/arrow-left'
import { EditIcon } from 'src/components/icons/edit'
import { AutoRpmTextField, deleteAutoRpmDialog, SchedulerDialog } from '../components'
import { SchedulerIcon } from 'src/components/icons/scheduler'
import moment from 'moment'
import AutoRpmsConfig from './auto-rpm-config'

export type proptypes = {
}
export const defaultProps: proptypes = {
}

const _AutoRpms: React.FC<proptypes & { className?: string }> = (props) => {
  const { className } = props
  const [newRpmName, setNewRpmName] = useState<string>('')
  const [editName, setEditName] = useState<string>('')
  const [rpmId, setRpmId] = useState<number>()
  const [id, setId] = useState<number>()
  const [openScheduler, setOpenScheduler] = useState<boolean>(false)
  const [openConfig, setOpenConfig] = useState<boolean>(false)
  const [openEdit, setOpenEdit] = useState<boolean>(false)
  const [openDelete, setOpenDelete] = useState<boolean>(false)
  const [hasAccess, setHasAccess] = useState<boolean>(false)
  const [openAlert, setOpenAlert] = useState<boolean>(false)
  const autoRpms = useTypedAppSelector(s => s.intouchState.autoRpmList)
  const milkPens = useTypedAppSelector(s => s.intouchState.milkPens)
  const feedPens = useTypedAppSelector(s => s.intouchState.feedPens)
  const autoRpmClients = useTypedAppSelector(s => s.intouchState.autoRpmClients)
  const addAutoRpmStatus = useTypedAppSelector(s => s.intouchState.addAutoRpmStatus)
  const scheduler = useTypedAppSelector(s => s.intouchState.autoRpmScheduler)
  const schedulerStatus = useTypedAppSelector(s => s.intouchState.autoRpmSchedulerStatus)
  const checkDuplicate = useTypedAppSelector(intouchSelectors.checkDuplicate)
  const { setLoading } = useContext(AppBarLoadingContext)
  const { setMessage } = useContext(SnackBarContext)
  const { enabledSecurity } = useContext(EnabledSecurityContext)
  const apiRef = useGridApiRef()
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams<{ id: string }>()
  const CustomGridActionsCellItem = GridActionsCellItem as any

  const columns = [
    {
      field: 'RpmTitle',
      headerName: 'Name',
      flex: 0.7,
      sortComparator: (a, b) => a?.toLowerCase() < b?.toLowerCase() ? -1 : 1,
      renderCell: (p: GridRenderCellParams) => <div style={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip title='Edit Name'>
          <IconButton style={{ marginRight: 8 }} size='small' onClick={() => { setRpmId(p.row?.AutoRpmId); setEditName(p.value || ''); setOpenEdit(true)}} disabled={hasAccess}>
            <EditIcon size={22} />
          </IconButton>
        </Tooltip>
        {p.value}
      </div>
    },
    {
      field: 'AutoRpmScheduleCron',
      headerName: 'Schedule',
      flex: 1,
      sortable: false,
      renderCell: (p: GridRenderCellParams) => {
        return <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          {p?.value ? p.value : 'No scheduler has been set up'}
        </div>
      }
    },
    {
      field: 'AutoRpmScheduleEnabled',
      headerName: 'Scheduler Status',
      flex: 0.3,
      sortable: false,
      renderCell: (p: GridRenderCellParams) => {
        return <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          {p.value ? 'Enabled' : 'Disabled'}
        </div>
      }
    },
    {
      field: 'actions',
      flex: 0.3,
      minWidth: 110,
      type: 'actions',
      sortable: false,
      filterable: false,
      getActions: (p: GridRowParams) => [
        <CustomGridActionsCellItem
          className='icons'
          icon={<Tooltip title='Configure' followCursor><span><SettingsIcon size={25} /></span></Tooltip>}
          label='Configure'
          onClick={() => { setRpmId(p.row.AutoRpmId); setOpenConfig(true) }}
          disabled={hasAccess}
        />,
        <CustomGridActionsCellItem
          className='icons'
          icon={<Tooltip title='Scheduler Settings' followCursor><span><SchedulerIcon size={25} /></span></Tooltip>}
          label='Scheduler Settings'
          onClick={() => { dispatch(intouchAction.requestAutoRpmScheduler({ id: p.row?.AutoRpmId })); setRpmId(p.row?.AutoRpmId); setOpenScheduler(true) }}
          disabled={hasAccess}
        />,
        <CustomGridActionsCellItem
          className='icons'
          icon={<Tooltip title='Delete' followCursor><span><BinIcon size={25} /></span></Tooltip>}
          label='Delete'
          onClick={() => { setRpmId(p.row.AutoRpmId); setOpenDelete(true) }}
          disabled={hasAccess}
        />
      ]
    }
  ]

  const addRpm = () => {
    if (id) {
      dispatch(intouchAction.addAutoRpm({ RpmTitle: newRpmName, ProviderClientMappingId: id, Enabled: true }))
      setNewRpmName('')
    }
  }

  const deleteRpm = () => rpmId && dispatch(intouchAction.deleteAutoRpm({ rpmId: rpmId.toString() }))

  const updateRpmName = str => {
    if (!rpmId || !id) return
    dispatch(intouchAction.updateAutoRpm({ AutoRpmId: rpmId, RpmTitle: str, ProviderClientMappingId: id, Enabled: true }))
  }

  const saveScheduler = (str: string, startDate: string, endDate: string | null, tz: string) => {
    if (tz === '') {
      setMessage({ message: 'Please select a timezone', severity: 'error', clear: null})
      return
    }
    if (!id) return
    rpmId && dispatch(intouchAction.submitAutoRpmScheduler({ AutoRpmId: rpmId, CronSchedule: str, Enabled: true, StartDate: startDate, EndDate: endDate, TimeZone: tz }))
    setOpenScheduler(false)
  }

  const disableScheduler = tz => {
    if (!id) return
    if (scheduler?.value?.[0])
      dispatch(intouchAction.submitAutoRpmScheduler({
        ...scheduler?.value[0],
        TimeZone: tz,
        StartDate: moment(scheduler?.value[0]?.StartDate).format('YYYY/MM/DD'),
        EndDate: scheduler?.value[0]?.EndDate !== null ? moment(scheduler?.value[0]?.EndDate).format('YYYY/MM/DD') : null,
        Enabled: false
      }))
    else setMessage({ message: 'No scheduler to be disabled', severity: 'error', clear: null })
  }

  const enableScheduler = tz => {
    if (!id) return
    if (scheduler?.value?.[0])
      dispatch(intouchAction.submitAutoRpmScheduler({
        ...scheduler?.value[0],
        TimeZone: tz,
        StartDate: moment(scheduler?.value[0]?.StartDate).format('YYYY/MM/DD'),
        EndDate: scheduler?.value[0]?.EndDate !== null ? moment(scheduler?.value[0]?.EndDate).format('YYYY/MM/DD') : null,
        Enabled: true
      }))
    else setMessage({ message: 'No scheduler to be enabled', severity: 'error', clear: null })
  }

  useEffect(() => {
    setHasAccess(!sortSubFeatures('intouch', 18, enabledSecurity))
  }, [enabledSecurity])

  useEffect(() => {
    if (!autoRpmClients || !id) return
    dispatch(intouchAction.requestAutoRpms({ id: id.toString() }))
    dispatch(intouchAction.requestFeedPens({ clientId: autoRpmClients.internal }))
    dispatch(intouchAction.requestMilkPens({ clientId: autoRpmClients.external }))
  }, [id])

  useEffect(() => {
    let { id } = params
    setId(parseInt(id))
  }, [])

  useEffect(() => {
    setLoading(autoRpms?.loading || scheduler?.loading || feedPens?.loading || milkPens?.loading || addAutoRpmStatus?.loading || schedulerStatus?.loading ? true : false)
  }, [autoRpms?.loading, scheduler?.loading, feedPens?.loading, milkPens?.loading, addAutoRpmStatus?.loading, schedulerStatus?.loading])

  useEffect(() => {
    autoRpms?.message && setMessage({ message: autoRpms.message.msg, severity: autoRpms.message.level, clear: () => dispatch(intouchAction.clearAutoRpmsMessage()) })
    addAutoRpmStatus?.message && setMessage({ message: addAutoRpmStatus.message.msg, severity: addAutoRpmStatus.message.level, clear: () => dispatch(intouchAction.clearAddAutoRpmsMessage()) })
    scheduler?.message && setMessage({ message: scheduler.message.msg, severity: scheduler.message.level, clear: () => dispatch(intouchAction.clearAutoRpmSchedulerMessage()) })
    schedulerStatus?.message && setMessage({ message: schedulerStatus.message.msg, severity: schedulerStatus.message.level, clear: () => dispatch(intouchAction.clearSubmitAutoRpmSchedulerMessage()) })
    
    if (schedulerStatus?.message?.level === 'success') {
      if (!id) return
      setOpenScheduler(false)
      dispatch(intouchAction.requestAutoRpms({ id: id.toString() }))
    }

    if (addAutoRpmStatus?.message?.level === 'success')
      setOpenAlert(true)
  }, [autoRpms?.message, scheduler?.message, addAutoRpmStatus?.message, schedulerStatus?.message])

  const configDialog = <Dialog className={className} fullWidth={true} maxWidth='md' open={openConfig} onClose={() => setOpenConfig(false)}>
    <DialogContent className='dialog-container'>
      <div className='text'>Click outside of popup or the close button to close popup</div>
      {(autoRpms?.value?.[0]?.AutoRpmConfigs || []).length > 0 && checkDuplicate && <div className='duplicate-text'>
        <div style={{ width: '100%' }}>
          Data duplication detected with this RPM. Please contact IT department, providing screenshots and relevant details to resolve this issue.
        </div>
      </div>}
      <AutoRpmsConfig rpmId={rpmId} setOpenConfig={setOpenConfig} />
    </DialogContent>
  </Dialog>

  return <div className={className}>
    <Paper className='paper'>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Button variant='contained' color='primary' size='small' startIcon={<ArrowLeftIcon size={20} />} onClick={() => history.push({ pathname: `/intouch/ProviderMappings`, state: { page: 'autorpm' } })}>
          Back
        </Button>
        <Typography variant='h6'>Auto RPM</Typography>
        <div style={{ width: 92 }}></div>
      </div>
      <div style={{ marginTop: 20 }}>
        <div className='textfield-div'>
          <TextField
            sx={{ width: 400, marginRight: '20px' }}
            label='New Auto RPM'
            size='small'
            value={newRpmName}
            onChange={e => setNewRpmName(e.target.value)}
            inputProps={{ style: { fontSize: 14, padding: 10 } }}
          />
          <Button color='primary' variant='contained' size='small' disabled={newRpmName?.length === 0} onClick={addRpm}>Add RPM</Button>
        </div>
        <div style={{ width: '80%', margin: '0 auto' }}>
          <DataGridComponent
            title=' '
            data={autoRpms?.value || []}
            columns={columns as any}
            apiRef={apiRef}
            rowHeight={45}
            rowsPerPage={10}
            defaultRowId='AutoRpmId'
            loading={autoRpms?.loading || addAutoRpmStatus?.loading || scheduler?.loading}
            defaultState={{ sorting: { sortModel: [{ field: 'RpmTitle', sort: 'asc' }] }}}
          />
        </div>
      </div>
    </Paper>
    {openEdit && <AutoRpmTextField defaultName={editName} save={updateRpmName} open={openEdit} setOpen={setOpenEdit} />}
    {openScheduler && <SchedulerDialog
      scheduler={scheduler}
      onSave={saveScheduler}
      onEnable={enableScheduler}
      onDisable={disableScheduler}
      open={openScheduler}
      setOpen={setOpenScheduler}
      loading={schedulerStatus?.loading}
    />}
    {deleteAutoRpmDialog(openDelete, setOpenDelete, deleteRpm)}
    {openConfig && configDialog}
    <Snackbar
      open={openAlert}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      onClose={() => setOpenAlert(false)}
    >
      <Alert severity='info'>To complete setup for the new Auto RPM, please configure the pens and set up the scheduler.</Alert>
    </Snackbar>
  </div>
}

export const AutoRpmsPage = styled(_AutoRpms)`
  display: flex;
  flex-direction: column;
  width: 100%;
  .paper {
    width: 100%;
    height: 100%;
    min-height: 70vh;
    padding: 10px;
    .textfield-div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      margin-bottom: 30px;
    }
  }
  .dialog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    .text {
      margin-bottom: 12px;
      color: ${p => p.theme.palette.alltech.dark_grey[80]};
      font-size: 12px;
    }
    .duplicate-text {
      display: flex;
      justify-content: center;
      color: ${p => p.theme.palette.alltech.red.main};
      font-size: 13px;
      margin: 10px 8px;
    }
  }
  .grey-color {
    color: ${p => p.theme.palette.alltech.light_grey.main};
  }
`
export default AutoRpmsPage