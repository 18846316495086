import React from 'react'
import { Button, CircularProgress, ClickAwayListener, Dialog, DialogActions, DialogContent, Grow, lighten, MenuItem, MenuList, Paper, Tooltip } from '@mui/material'
import { ActiveFilterIcon } from 'src/components/icons/active-filter'
import { FilterIcon } from 'src/components/icons/filter'
import { StyledPopper } from '../../rpm/rpm-validation'
import { CircleTickIcon } from 'src/components/icons/circle-tick'
import { CircleIcon } from 'src/components/icons/circle'
import { API_NS } from 'src/lib/api/endpoints/endpoint.types'
import { AsyncProcessState } from 'src/store/types/shared-state-types'
import { CronSchedulerComponent } from 'src/components/scheduler/cronScheduler'

type deleteProptypes = {
  openDelete: boolean;
  setOpenDelete: (b: boolean) => void;
  deleteMapping: () => void;
}

export const DeletePMDialog: React.FC<deleteProptypes> = (props) => {
  const { openDelete, setOpenDelete, deleteMapping } = props

  return <Dialog fullWidth open={openDelete} onClose={() => setOpenDelete(false)}>
    <DialogContent style={{ fontSize: 15 }}>Are you sure you want to delete this provider mapping?</DialogContent>
    <DialogActions>
      <Button size='small' onClick={() => { setOpenDelete(false); deleteMapping() }}>Confirm</Button>
      <Button size='small' onClick={() => setOpenDelete(false)}>Cancel</Button>
    </DialogActions>
  </Dialog>
}

export const headerMenu = (activeProviders, anchorRef, setOpenFilter, openFilter, theme, providers, setActiveProviders, activeProviderChange) => <React.Fragment>
  <Tooltip title='Filter' followCursor>
    <Button className='filter-button' color='primary' ref={anchorRef} onClick={() => setOpenFilter(!openFilter)}>
    {activeProviders.length > 0 ? <ActiveFilterIcon size={26} /> : <FilterIcon size={26} />}
    </Button>
  </Tooltip>
  <StyledPopper open={openFilter} anchorEl={anchorRef.current} role={undefined} placement='bottom-start' style={{ zIndex: 999 }} data-cy='filter-dropdown' transition>
    {({ TransitionProps }) => (<Grow {...TransitionProps} timeout={200}>
      <Paper sx={{ bgcolor: 'white', boxShadow: `0px 4px 8px ${theme.secondary[500]}` }}>
        <ClickAwayListener onClickAway={() => setOpenFilter(false)}>
          <div>
            <MenuItem id='clear-select' style={{ padding: '8px 16px', background: lighten(theme.lightGrey[50], 0.2), fontSize: 13 }} onClick={() => setActiveProviders([])} disabled={activeProviders.length === 0}>
              Clear Selection
            </MenuItem>
            <MenuList id='providers-menu' style={{ maxHeight: 300, overflowY: 'auto', paddingTop: 0 }} variant='menu'>
              {(providers?.value || []).map((p, i) => (
                <MenuItem style={{ padding: '6px 8px', fontSize: 13 }} key={`${p.ProviderCode}-${p.ProviderName}`} onClick={() => activeProviderChange(p.ProviderName)} selected={activeProviders.includes(p.ProviderName)}>
                  {activeProviders.includes(p.ProviderName) ? <CircleTickIcon size={25} /> : <CircleIcon size={25} />}
                  {p.ProviderName}
                </MenuItem>
              ))}
            </MenuList>
          </div>
        </ClickAwayListener>
      </Paper>
    </Grow>)}
  </StyledPopper>
</React.Fragment>

type SchedulerProptypes = {
  scheduler?: AsyncProcessState<API_NS.DDWSchedulerRes>;
  open: boolean;
  setOpen: (arg: boolean) => void;
  onSave: (str: string, startDate: string, endDate: string | null, timezone: string) => void,
  onDisable: (tz: string) => void,
  onEnable: (tz: string) => void,
  loading?: boolean
}

export const SchedulerDialog: React.FC<SchedulerProptypes & { className?: string }> = (props) => {
  const { className, scheduler, open, setOpen, onEnable, onDisable, onSave, loading } = props

  return <Dialog className={className} fullWidth maxWidth='md' open={open} onClose={() => setOpen(false)}>
    <DialogContent style={{ display: 'flex', justifyContent: 'center', padding: '14px 12px', maxHeight: '80%', width: '100%' }}>
      {scheduler?.loading || loading ? <div style={{ display: 'flex', alignItems: 'center' }}><CircularProgress size={30} />&nbsp;&nbsp;&nbsp; Please wait...</div>
      : <CronSchedulerComponent
        title='DDW Scheduler'
        scheduler={scheduler?.value?.[0]}
        onSubmit={onSave}
        onDisable={onDisable}
        onEnable={onEnable}
        submitLoading={scheduler?.loading || loading}
        onClose={() => setOpen(false)}
      />}
    </DialogContent>
  </Dialog>
}